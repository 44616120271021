// import Raven from "raven-js";
import fp from "lodash/fp";
import { log } from "../../shared/helpers/logger";

const CAPTURED_GUARD = "@@_CAPTURED_ERROR_@@";

const processPromiseRejectionEvent = event => {
  const isError = fp.isError(event.reason);

  return {
    data: isError ? null : event.reason,
    error: isError ? event.reason : new Error("Unknown Promise Rejection"),
  };
};

export const captureException = (error, options) => {
  const isError = fp.isError(error);

  if (isError) {
    if (error[CAPTURED_GUARD]) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    error[CAPTURED_GUARD] = true;
  }

  if (process.env.NODE_ENV === "production") {
    // Raven.captureException(error, options);
  } else {
    if (isError && error.stack) {
      console.error(error.stack);
    } else {
      console.error(error);
    }

    if (options) {
      console.error(options);
    }
  }
};

export const setUserContext = (id, email) => {
  if (process.env.NODE_ENV === "production") {
    // Raven.setUserContext({ id, email });
  } else {
    log("Raven.setUserContext", id, email);
  }
};

// if (process.env.NODE_ENV === "production") {
//  Raven.config("https://df78b2abf4ed4ff0800ee7785567552a@logs.wing.ae/6", {
//    environment: process.env.WING_ENV,
//    ignoreUrls: [
//      // Third party scripts
//      /akamaihd.net/i,
//      /plotswiden.com/i,
//      /lighterasses.com/i,
//      /davebestdeals.com/i,
//      /kaspersky-labs.com/i,
//      /graph\.facebook\.com/i,
//      /connect\.facebook\.net\/en_US\/all\.js/i,
//      // Chrome extensions
//      /extensions\//i,
//      /^chrome:\/\//i,
//    ],
//    ignoreErrors: [
//      // Wat!?
//      "null",
//      "undefined",
//      "[object Event]",
//      "vid_mate_check is not defined",
//      // Facebook borked
//      "fb_xd_fragment",
//      // Local storage errors
//      "InvalidStateError",
//      // DOM errors
//      'Permission denied to access property "setData"',
//      // Http errors
//      "Forbidden",
//      "Not Found",
//      "Bad Request",
//      "Authorization Required",
//      /^Request has been terminated.*/,
//      // Errors during rebuild
//      /^Loading chunk*/,
//    ],
//  });
//
//  Raven.install();
// }

window.addEventListener("rejectionhandled", event => {
  const { data, error } = processPromiseRejectionEvent(event);

  captureException(error, { extra: { data, handledPromise: true } });
});

window.addEventListener("unhandledrejection", event => {
  const { data, error } = processPromiseRejectionEvent(event);

  event.preventDefault();

  captureException(error, { extra: { data, unhandledPromise: true } });
});
