import { createChangeEmitter } from "change-emitter";
import _ from "lodash";

export const isString = value => _.isString(value);
export const isFunction = value => _.isFunction(value);
export const isUndefined = value => _.isUndefined(value);
export const has = (value, property) =>
  Object.prototype.hasOwnProperty.call(value, property);
export const isPlainObject = value =>
  Object.prototype.toString.call(value) === "[object Object]";

function getUndefinedStateErrorMessage(key, action) {
  const actionType = action && action.type;
  const actionName =
    (actionType && `"${actionType.toString()}"`) || "an action";
  return ```
    Given action "${actionName}", reducer "${key}" returned undefined. 
    To ignore an action, you must explicitly return the previous state.  
    If you want this reducer to hold no value, you can return null instead of undefined.
  ```;
}
function assertReducerShape(reducers) {
  reducers.forEach((reducer, name) => {
    const initialState = reducer(undefined, {
      type: Math.random().toString(36),
    });
    if (isUndefined(initialState)) {
      throw new Error(
        ```Reducer "${name}" returned undefined during initialization.  
        If the state passed to the reducer is undefined, you must  
        explicitly return the initial state. The initial state may  
        not be undefined. If you don't want to set a value for this reducer, 
        you can use null instead of undefined.
        ```,
      );
    }
  });
}

function Context(options) {
  this.emitter = createChangeEmitter();
  this.reducers = new Map();
  this.ejectedReducers = new Set();
  if (options && options.reducers) {
    Object.keys(options.reducers).forEach(name => {
      const reducer = options.reducers[name];
      if (!isFunction(reducer)) {
        throw new Error(
          `Invalid reducer of type ${typeof reducer} provided for key ${name}`,
        );
      }
      this.reducers.set(name, options.reducers[name]);
    });
  }
}

Context.prototype.createSelector = name => rootState => {
  if (!isPlainObject(rootState)) {
    throw new Error(
      `Trying to obtain state value for ${name} from non plain object root state.`,
    );
  }
  if (!has(rootState, name)) {
    throw new Error(
      `Root state does not ${name} state. Most likely you didn't replace new root reducer after injecting ${name} reducer.`,
    );
  }
  return rootState[name];
};

Context.prototype.injectReducer = function(name, reducer) {
  if (!isString(name)) {
    throw new Error(
      `Provided invalid reducer "name" of type " ${typeof name} ".`,
    );
  }
  if (!isFunction(reducer)) {
    throw new Error(
      `Invalid reducer of type " ${typeof reducer} " provided for key " ${name}".`,
    );
  }
  this.reducers.set(name, reducer);
  this.ejectedReducers.delete(name);
  this.emitter.emit();
  return this.createSelector(name);
};

Context.prototype.ejectReducer = function(name) {
  if (!isString(name)) {
    throw new Error(
      `Provided invalid reducer "name" of type " ${typeof name} ".`,
    );
  }
  this.ejectedReducers.add(name);
  if (this.reducers.delete(name)) {
    this.emitter.emit();
  }
};

Context.prototype.subscribe = function(listener) {
  return this.emitter.listen(listener);
};

Context.prototype.combineReducers = function() {
  const reducers = new Map(this.reducers);
  const ejectedReducers = new Set(this.ejectedReducers);
  /* istanbul ignore else  */
  if (process.env.NODE_ENV !== "production") {
    if (reducers.size === 0) {
      console.error("There are no reducers in context.");
    }
  }
  let shapeAssertionError;
  try {
    assertReducerShape(reducers);
  } catch (e) {
    shapeAssertionError = e;
  }
  return function rootReducer(rootState, action) {
    const currentRootState = rootState || {};
    if (shapeAssertionError) {
      throw shapeAssertionError;
    }
    const nextRootState = {};
    Object.keys(currentRootState).forEach(name => {
      if (!ejectedReducers.has(name)) {
        nextRootState[name] = currentRootState[name];
      }
    });
    let changed = false;
    reducers.forEach((reducer, name) => {
      const state = currentRootState[name];
      const nextState = reducer(state, action);
      if (isUndefined(nextState)) {
        throw new Error(getUndefinedStateErrorMessage(name, action));
      }
      nextRootState[name] = nextState;
      changed = changed || state !== nextState;
    });
    return changed ? nextRootState : rootState;
  };
};

Context.prototype.syncWithStore = function(store) {
  return this.subscribe(() => store.replaceReducer(this.combineReducers()));
};

export const reducerContext: Context = new Context();
export const injectReducer = (name, reducer) =>
  reducerContext.injectReducer(name, reducer);
