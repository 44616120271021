// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./blogger-sans-300.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./blogger-sans-300.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./blogger-sans-300.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./blogger-sans-300.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./blogger-sans-300.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./blogger-sans-400.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./blogger-sans-400.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./blogger-sans-400.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./blogger-sans-400.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./blogger-sans-400.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./blogger-sans-700.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./blogger-sans-700.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("./blogger-sans-700.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("./blogger-sans-700.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("./blogger-sans-700.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#blogger-sans-300" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___, { hash: "#blogger-sans-400" });
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___, { hash: "#blogger-sans-700" });
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___, { hash: "?#iefix" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-weight: 300;
    font-style: normal;
    font-family: 'Blogger Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('embedded-opentype');
}

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Blogger Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('svg'),
    url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('embedded-opentype');
}

@font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Blogger Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_12___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format('svg'),
    url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format('embedded-opentype');
}`, "",{"version":3,"sources":["webpack://./src/client/theme/fonts/blogger-sans/blogger-sans.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,4CAAkC;IAClC;;;;uEAIgE;AACpE;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,4CAAkC;IAClC;;;;wEAIgE;AACpE;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,6CAAkC;IAClC;;;;wEAIgE;AACpE","sourcesContent":["@font-face {\n    font-weight: 300;\n    font-style: normal;\n    font-family: 'Blogger Sans';\n    src: url('./blogger-sans-300.eot');\n    src: url('./blogger-sans-300.woff2') format('woff2'),\n    url('./blogger-sans-300.woff') format('woff'),\n    url('./blogger-sans-300.ttf') format('truetype'),\n    url('./blogger-sans-300.svg#blogger-sans-300') format('svg'),\n    url('./blogger-sans-300.eot?#iefix') format('embedded-opentype');\n}\n\n@font-face {\n    font-weight: 400;\n    font-style: normal;\n    font-family: 'Blogger Sans';\n    src: url('./blogger-sans-400.eot');\n    src: url('./blogger-sans-400.woff2') format('woff2'),\n    url('./blogger-sans-400.woff') format('woff'),\n    url('./blogger-sans-400.ttf') format('truetype'),\n    url('./blogger-sans-400.svg#blogger-sans-400') format('svg'),\n    url('./blogger-sans-400.eot?#iefix') format('embedded-opentype');\n}\n\n@font-face {\n    font-weight: 700;\n    font-style: normal;\n    font-family: 'Blogger Sans';\n    src: url('./blogger-sans-700.eot');\n    src: url('./blogger-sans-700.woff2') format('woff2'),\n    url('./blogger-sans-700.woff') format('woff'),\n    url('./blogger-sans-700.ttf') format('truetype'),\n    url('./blogger-sans-700.svg#blogger-sans-700') format('svg'),\n    url('./blogger-sans-700.eot?#iefix') format('embedded-opentype');\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
