import { Map } from "immutable";
import {
  ACTIVE as ACTIVE_STATUS,
  INACTIVE as INACTIVE_STATUS,
} from "./OverallStatus";

export const ALL = "all";
export const ACTIVE = ACTIVE_STATUS;
export const INACTIVE = INACTIVE_STATUS;

export default Map({ [ALL]: null, [ACTIVE]: "active", [INACTIVE]: "inactive" });
