import utf8 from "crypto-js/enc-utf8";
import base64 from "crypto-js/enc-base64";

export const base64Decode = v => {
  const words = base64.parse(v);

  return utf8.stringify(words);
};

export const base64Encode = v => {
  const words = utf8.parse(v);

  return base64.stringify(words);
};

export function base64ToObject(value) {
  try {
    return JSON.parse(base64Decode(value));
  } catch (e) {
    return null;
  }
}
