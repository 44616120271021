import React, { createContext, useEffect, useMemo, useState } from "react";
import { compose, lifecycle } from "recompose";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect, Provider } from "react-redux";
import { browserHistory, Router } from "react-router";
import { AppContainer } from "react-hot-loader";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { getInitialState } from "../../helpers/InitialState";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import DateFnsUtils from "@date-io/date-fns";
import "../../theme/main-styles";
import { mapTheme } from "../../../shared/helpers/ThemeMapper";
import mainTheme from "../../../shared/theme/main-theme";
import { getAppTheme } from "../../../shared/reducers/AppReducer";
import { ruRU } from "@material-ui/core/locale";
/* eslint-disable */
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import uzLocale from "date-fns/locale/uz";
import {
  getUserLanguage,
  getUserWarehouseId,
} from "../../reducers/ProfileReducer";
import { api } from "../../api/shared/BaseApi";
import { isTokenValid } from "../../../shared/reducers/AuthReducer";

/* eslint-enable */
const initialState = getInitialState();
const theme = getAppTheme(initialState);
const localeMap = {
  en: enLocale,
  ru: ruLocale,
  uz: uzLocale,
};
const getAppKey = () =>
  process.env.NODE_ENV === "production" ? "app" : Math.random();

const enhancer = compose(
  connect(state => ({
    userWarehouseId: getUserWarehouseId(state),
    isRTL: getIsRTL(state),
    lang: getUserLanguage(state),
    isTokenValid: isTokenValid(state),
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.isRTL) {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
      // if (this.props.isTokenValid) {
      //     socket.connectSocket();
      // }
    },
    componentDidUpdate() {
      if (this.props.isRTL) {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
    },
  }),
);

ClientApp.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  isRTL: PropTypes.bool,
  lang: PropTypes.string,
  userWarehouseId: PropTypes.number,
};

const selectMuiTheme = () => {
  switch (theme) {
    default:
      return { ...mainTheme };
  }
};
export const GlobalContext = createContext({
  uw: null,
  setUW: () => {},
});

function ClientApp({ userWarehouseId, lang, routes, store }) {
  const [uw, setUW] = useState(null);
  const value = useMemo(() => ({ uw, setUW }), [uw]);
  useEffect(() => {
    if (localStorage.getItem("uw") === "null" && userWarehouseId)
      setUW(userWarehouseId);
  }, [userWarehouseId]);
  useEffect(() => {
    if (localStorage.getItem("uw") !== "null") {
      setUW(localStorage.getItem("uw"));
    }
  }, []);

  useEffect(() => {
    if (uw) {
      localStorage.setItem("uw", uw);
      api.uw = uw;
    }
  }, [uw]);

  const muiTheme = selectMuiTheme();

  // useEffect(
  //     () => () => {
  //         socket.disconnectSocket();
  //     },
  //     [],
  // );

  const mappped = createMuiTheme(mapTheme(muiTheme), ruRU);
  return (
    <GlobalContext.Provider value={value}>
      <AppContainer
        errorReporter={
          process.env.NODE_ENV === "production" &&
          // eslint-disable-next-line global-require,no-undef
          require("redbox-react").default
        }
      >
        <MuiThemeProvider theme={mappped}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={localeMap[lang]}
          >
            <Provider store={store}>
              <Router
                key={getAppKey()}
                routes={routes}
                history={browserHistory}
              />
            </Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </AppContainer>
    </GlobalContext.Provider>
  );
}

export default enhancer(ClientApp);
