import { Observable } from "rxjs";
import fp from "lodash/fp";
import loadScript from "load-script";
import { ObservableCache } from "./loading-cache";
import { getHash } from "./DataUtils";
import { getInitialStateForHelper } from "./InitialState";
import { updateQuery } from "../../shared/helpers/UrlUtils";
import {
  getMapProvider,
  getMapProviderKey,
} from "../../shared/reducers/AppReducer";

const SCRIPT_LOAD_CALLBACK = "__wing_google_maps_callback__";

// const url = parseUrl(window.location);
const state = getInitialStateForHelper();
// export const googleMapKey = getMapKey(state);

export const googleMapKey =
  getMapProvider(state) === "google_map" ? getMapProviderKey(state) : "";

const MAP_URL = updateQuery("https://maps.googleapis.com/maps/api/js", {
  language: "en",
  callback: SCRIPT_LOAD_CALLBACK,
  libraries: ["places", "drawing", "visualization"].join(","),
  key: googleMapKey,
});

const ResponseCode = {
  OK: "OK",
  ZERO_RESULTS: "ZERO_RESULTS",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  MAX_WAYPOINTS_EXCEEDED: "MAX_WAYPOINTS_EXCEEDED",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
};

let sessionToken = null;
const maxCacheAge = 5 * 60 * 1000;
const getMaps = fp.get("google.maps");
const toPlainObject = value => JSON.parse(JSON.stringify(value));

function loadMaps() {
  if (!loadMaps.promise) {
    loadMaps.promise = new Promise(resolve => {
      const maps = getMaps(window);

      if (maps) {
        resolve(maps);
      } else {
        loadScript(MAP_URL);

        window[SCRIPT_LOAD_CALLBACK] = () => {
          delete window[SCRIPT_LOAD_CALLBACK];

          resolve(getMaps(window));
        };
      }
    });
  }
  return loadMaps.promise;
}

export const getMapsStream = () => Observable.defer(loadMaps);

const placesCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getMapsStream()
      .switchMap(maps =>
        Observable.create(observer => {
          const service = new maps.places.PlacesService(
            document.createElement("div"),
          );

          const request = {
            sessionToken,
            placeId: query.placeId,
            fields: [
              "name",
              "formatted_address",
              "geometry",
              "place_id",
              "address_component",
            ],
          };

          service.getDetails(request, (response, status) => {
            switch (status) {
              case ResponseCode.OK:
                observer.next(response);
                break;
              case ResponseCode.ZERO_RESULTS:
              case ResponseCode.UNKNOWN_ERROR:
                observer.next({});
                break;
              default:
                observer.error(new Error(status));
            }
            sessionToken = null;
            observer.complete();
          });
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      .map(toPlainObject),
});

export const placeDetails = request => placesCache.get(request);

const geocodeCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getMapsStream()
      .switchMap(maps =>
        Observable.create(observer => {
          const service = new maps.Geocoder();

          service.geocode(query, (response, status) => {
            switch (status) {
              case ResponseCode.OK:
                observer.next(response);
                break;
              case ResponseCode.ZERO_RESULTS:
              case ResponseCode.UNKNOWN_ERROR:
                observer.next([]);
                break;
              default:
                observer.error(new Error(status));
            }

            observer.complete();
          });
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      .map(toPlainObject),
});

export const geocode = request => geocodeCache.get(request);

const autocompleteCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getMapsStream()
      .switchMap(maps =>
        Observable.create(observer => {
          if (!sessionToken) {
            sessionToken = new maps.places.AutocompleteSessionToken();
          }
          const service = new maps.places.AutocompleteService();

          service.getPlacePredictions(
            { ...query, sessionToken },
            (response, status) => {
              switch (status) {
                case ResponseCode.OK:
                  observer.next(response);
                  break;
                case ResponseCode.ZERO_RESULTS:
                case ResponseCode.UNKNOWN_ERROR:
                  observer.next([]);
                  break;
                default:
                  observer.error(new Error(status));
              }

              observer.complete();
            },
          );
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      .map(toPlainObject),
});

export const getPlacePredictions = request => autocompleteCache.get(request);

const directionsCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getMapsStream()
      .switchMap(maps =>
        Observable.create(observer => {
          const service = new maps.DirectionsService();

          service.route(
            { travelMode: maps.TravelMode.DRIVING, ...query },
            (response, status) => {
              switch (status) {
                case ResponseCode.OK:
                  observer.next(response);
                  break;
                case ResponseCode.ZERO_RESULTS:
                case ResponseCode.UNKNOWN_ERROR:
                  observer.next(null);
                  break;
                case ResponseCode.MAX_WAYPOINTS_EXCEEDED:
                case ResponseCode.OVER_QUERY_LIMIT:
                  observer.error(new Error(status));
                  break;
                default:
                  observer.error(new Error(status));
              }

              observer.complete();
            },
          );
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      // .retryWhen(errorSubject =>
      //   errorSubject.switchMap(
      //     error =>
      //       method === GET && error.errno === "ETIME"
      //         ? Observable.of(error)
      //         : Observable.throw(error),
      //   ),
      // );
      .map(toPlainObject),
});

export const getDirectionRoute = options => directionsCache.get(options);
