import { Map } from "immutable";

export const ALL = "all";
export const WITHOUT_DRIVER = "without-driver";
export const WITH_ORDER = "with-order";

export default Map({
  [ALL]: null,
  [WITHOUT_DRIVER]: "without_driver",
  [WITH_ORDER]: "with_order",
});
