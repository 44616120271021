import { deleteElement } from "./ElementUtils";
import { INITIAL_STATE } from "../../shared/config/AppConfig";
import { fromBase64 } from "../../shared/helpers/DataSerializer";

const state = obtainState();

function obtainState() {
  const input = document.getElementById(INITIAL_STATE);
  const value = input && input.value;

  deleteElement(input);

  return value;
}

export function getInitialState() {
  if (!state) {
    throw new Error(`No initial state`);
  }

  if (!getInitialState.state) {
    getInitialState.state = fromBase64(state);
  }

  return getInitialState.state;
}

export function getInitialStateForHelper() {
  if (!state) {
    return null;
  }

  if (!getInitialState.state) {
    getInitialState.state = fromBase64(state);
  }

  return getInitialState.state;
}
