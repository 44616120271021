import React from "react";
import ReactDOM from "react-dom";

// eslint-disable-next-line import/no-internal-modules
import "../../assets/css/roboto.css";
// eslint-disable-next-line import/no-internal-modules
import "../../assets/css/material-icons.css";
import { getStore } from "./configs/ClientAppConfig";
import { configureRoutes } from "./configs/ConfigureClientRoutes";
import { deleteElement, obtainElement } from "./helpers/ElementUtils";
import { captureException } from "./helpers/ErrorTracker";
import { getInitialState } from "./helpers/InitialState";
import { SSR_STYLE_SHEETS } from "../shared/config/AppConfig";
import { getAppName } from "../shared/reducers/AppReducer";
import ClientApp from "./containers/shared/ClientApp";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

const mountNode = obtainElement("content");
const appName = getAppName(getInitialState());

// Sentry.init({
//   // dsn: "http://0d983d08c17a42ffbbc0edb77bdf116e@192.168.40.51:9000/3",
//   dsn: "http://0d983d08c17a42ffbbc0edb77bdf116e@localhost:9000/3",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Promise.all([getStore(), configureRoutes(appName)])
  .then(
    ([store, routes]) =>
      new Promise((resolve) => {
        ReactDOM.render(
          <ClientApp store={store} routes={routes} />,
          mountNode,
          resolve,
        );
      }),
  )
  .then(() => deleteElement(SSR_STYLE_SHEETS))
  .catch((error) => captureException(error));

if (module.hot) {
  module.hot.accept();
}

// test
