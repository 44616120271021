import { Observable } from "rxjs";
import fp from "lodash/fp";
import { captureException } from "./ErrorTracker";

export const takeWhileInclusive = predicate => source =>
  new Observable(observer => {
    const subscription = source.subscribe({
      next: value => {
        observer.next(value);

        if (!predicate(value)) {
          observer.complete();
        }
      },
      error: error => observer.error(error),
      complete: () => observer.complete(),
    });

    return () => subscription.unsubscribe();
  });

export const pipeStreams = fp.rest(
  fp.flow(fp.map(fn => fp.flow(fn, x => x.share())), fp.spread(fp.flow)),
);

export const mergeSideEffectStreams = (...streams) =>
  Observable.merge(...streams)
    .catch(error => {
      // Capture exception and emit nothing,
      // so this stream will not break execution.
      captureException(error);

      return Observable.never();
    })
    // Switch to `never` so this stream will emit nothing.
    .switchMapTo(Observable.never());
