export const SIGN_IN_PATH = "sign-in";
export const SIGN_IN_URL = `/${SIGN_IN_PATH}`;

export const SIGN_OUT_PATH = "sign-out";
export const SIGN_OUT_URL = `/${SIGN_OUT_PATH}`;

export const RECOVER_PASSWORD_PATH = "recover-password(/:email)";
export const RECOVER_PASSWORD_URL = "/recover-password";

export const CHANGE_PASSWORD_PATH = "change-password(/:key)";
export const CHANGE_PASSWORD_URL = "/change-password/";

export const DASHBOARD_PATH = "dashboard";
export const DASHBOARD_URL = `/${DASHBOARD_PATH}`;

export const PROFILE_PATH = "profile(/:tab)";
export const PROFILE_URL = `${DASHBOARD_URL}/profile`;
