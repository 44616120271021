import { updateQuery } from "../../shared/helpers/UrlUtils";
import canUseDom from "../../shared/helpers/canUseDom";

function setCookie(url) {
  if (canUseDom) {
    window.location.replace(url);
  }
}

function getLocationHref() {
  return canUseDom ? window.location.href : "/";
}

export function skipVerification(nextUrl) {
  setCookie(createSkipVerificationUrl(nextUrl));
}

export function createSkipVerificationUrl() {
  return updateQuery(getLocationHref(), query => ({
    ...query,
    skipVerification: 1,
  }));
}

export function setToken(jwt) {
  setCookie(createSetTokenUrl(jwt));
}

export function createSetTokenUrl(jwt) {
  return updateQuery(getLocationHref(), query => ({ ...query, jwt }));
}

export function setUserToken(jwt, cwt) {
  setCookie(createSetUserTokenUrl(jwt, cwt));
}

export function createSetUserTokenUrl(jwt, cwt) {
  return updateQuery(getLocationHref(), query => ({ ...query, jwt, cwt }));
}
