import fp from "lodash/fp";
import { captureException } from "../helpers/ErrorTracker";
import {
  ADMIN,
  TOOLS,
  CUSTOMER,
  SUPPLIER,
  ALLOCATION,
  QALLOCATION,
} from "../../server/constants/AppTypes";

const getPromise = (app) => {
  switch (app) {
    case ADMIN:
      return import(/* webpackChunkName: "admin" */ "../routes/admin-routes");
    case CUSTOMER:
      return import(
        /* webpackChunkName: "customer" */ "../routes/customer-routes"
      );
    case SUPPLIER:
      return import(
        /* webpackChunkName: "supplier" */ "../routes/supplier-routes"
      );
    case TOOLS:
      return import(
        /* webpackChunkName: "supplier" */ "../routes/tools-routes"
      );
    case ALLOCATION:
    case QALLOCATION:
      return import(
        /* webpackChunkName: "supplier" */ "../routes/allocation-routes"
      );
    default:
      return Promise.reject(
        new Error(`Could not resolve routes for provided app: ${app}`),
      );
  }
};

export const configureRoutes = (app) =>
  getPromise(app)
    .then(fp.get("default"))
    .catch((error) => {
      captureException(error, { extra: { app } }, () => {
        if (process.env.NODE_ENV === "production") {
          window.location.reload(true);
        }
      });

      throw error;
    });
