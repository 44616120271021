import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";

const cleanupProps = fp.omit(["sheet", "classes", "containerElement"]);

const enhancer = compose(
  withTheme,
  useSheet(
    {
      root: {
        padding: 0,
        border: "none",
        cursor: "text",
        outline: "none",
        boxShadow: "none",
        userSelect: "all",
        lineHeight: "inherit",
        fontSize: "inherit",
        textAlign: "inherit",
        textDecoration: "none",
        backgroundColor: "transparent",
        fontFamily: "Roboto, Arial, sans-serif",
      },
      link: {
        color: props => props.theme.palette.accent1Color,
        fontWeight: 500,
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
          color: props => props.theme.palette.primary1Color,
        },
      },
    },
    { important: false },
  ),
);

LinkButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  containerElement: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

LinkButton.defaultProps = {
  type: "button",
  containerElement: "button",
};

function LinkButton(props) {
  const elementProps = cleanupProps(props);

  elementProps.className = cx(props.classes.root, props.className, {
    [props.classes.link]: Boolean(props.onClick),
  });

  return React.isValidElement(props.containerElement)
    ? React.cloneElement(props.containerElement, elementProps, props.children)
    : React.createElement(props.containerElement, elementProps, props.children);
}

export default enhancer(LinkButton);
