import { fromJS } from "immutable";

export const phoneCountyCodeList = [
  { country: "United Arab Emirates", code: "+971", shortcode: "AE" },
  { country: "Afghanistan", code: "+93", shortcode: "AF" },
  { country: "Albania", code: "+355", shortcode: "AL" },
  { country: "Algeria", code: "+213", shortcode: "DZ" },
  { country: "American Samoa", code: "+1 684", shortcode: "AS" },
  { country: "Andorra", code: "+376", shortcode: "AD" },
  { country: "Angola", code: "+244", shortcode: "AO" },
  { country: "Anguilla", code: "+1 264", shortcode: "AI" },
  { country: "Antigua and Barbuda", code: "+1268", shortcode: "AG" },
  { country: "Argentina", code: "+54", shortcode: "AR" },
  { country: "Armenia", code: "+374", shortcode: "AM" },
  { country: "Aruba", code: "+297", shortcode: "AW" },
  { country: "Australia", code: "+61", shortcode: "AU" },
  { country: "Austria", code: "+43", shortcode: "AT" },
  { country: "Azerbaijan", code: "+994", shortcode: "AZ" },
  { country: "Bahamas", code: "+1 242", shortcode: "BS" },
  { country: "Bahrain", code: "+973", shortcode: "BH" },
  { country: "Bangladesh", code: "+880", shortcode: "BD" },
  { country: "Barbados", code: "+1 246", shortcode: "BB" },
  { country: "Belarus", code: "+375", shortcode: "BY" },
  { country: "Belgium", code: "+32", shortcode: "BE" },
  { country: "Belize", code: "+501", shortcode: "BZ" },
  { country: "Benin", code: "+229", shortcode: "BJ" },
  { country: "Bermuda", code: "+1 441", shortcode: "BM" },
  { country: "Bhutan", code: "+975", shortcode: "BT" },
  { country: "Bosnia and Herzegovina", code: "+387", shortcode: "BA" },
  { country: "Botswana", code: "+267", shortcode: "BW" },
  { country: "Brazil", code: "+55", shortcode: "BR" },
  { country: "British Indian Ocean Territory", code: "+246", shortcode: "IO" },
  { country: "Bulgaria", code: "+359", shortcode: "BG" },
  { country: "Burkina Faso", code: "+226", shortcode: "BF" },
  { country: "Burundi", code: "+257", shortcode: "BI" },
  { country: "Cambodia", code: "+855", shortcode: "KH" },
  { country: "Cameroon", code: "+237", shortcode: "CM" },
  { country: "Canada", code: "+1", shortcode: "CA" },
  { country: "Cape Verde", code: "+238", shortcode: "CV" },
  { country: "Cayman Islands", code: "+345", shortcode: "KY" },
  { country: "Central African Republic", code: "+236", shortcode: "CF" },
  { country: "Chad", code: "+235", shortcode: "TD" },
  { country: "Chile", code: "+56", shortcode: "CL" },
  { country: "China", code: "+86", shortcode: "CN" },
  { country: "Christmas Island", code: "+61", shortcode: "CX" },
  { country: "Colombia", code: "+57", shortcode: "CO" },
  { country: "Comoros", code: "+269", shortcode: "KM" },
  { country: "Congo", code: "+242", shortcode: "CG" },
  { country: "Cook Islands", code: "+682", shortcode: "CK" },
  { country: "Costa Rica", code: "+506", shortcode: "CR" },
  { country: "Croatia", code: "+385", shortcode: "HR" },
  { country: "Cuba", code: "+53", shortcode: "CU" },
  { country: "Cyprus", code: "+537", shortcode: "CY" },
  { country: "Czech Republic", code: "+420", shortcode: "CZ" },
  { country: "Denmark", code: "+45", shortcode: "DK" },
  { country: "Djibouti", code: "+253", shortcode: "DJ" },
  { country: "Dominica", code: "+1 767", shortcode: "DM" },
  { country: "Dominican Republic", code: "+1 849", shortcode: "DO" },
  { country: "Ecuador", code: "+593", shortcode: "EC" },
  { country: "Egypt", code: "+20", shortcode: "EG" },
  { country: "El Salvador", code: "+503", shortcode: "SV" },
  { country: "Equatorial Guinea", code: "+240", shortcode: "GQ" },
  { country: "Eritrea", code: "+291", shortcode: "ER" },
  { country: "Estonia", code: "+372", shortcode: "EE" },
  { country: "Ethiopia", code: "+251", shortcode: "ET" },
  { country: "Faroe Islands", code: "+298", shortcode: "FO" },
  { country: "Fiji", code: "+679", shortcode: "FJ" },
  { country: "Finland", code: "+358", shortcode: "FI" },
  { country: "France", code: "+33", shortcode: "FR" },
  { country: "French Guiana", code: "+594", shortcode: "GF" },
  { country: "French Polynesia", code: "+689", shortcode: "PF" },
  { country: "Gabon", code: "+241", shortcode: "GA" },
  { country: "Gambia", code: "+220", shortcode: "GM" },
  { country: "Georgia", code: "+995", shortcode: "GE" },
  { country: "Germany", code: "+49", shortcode: "DE" },
  { country: "Ghana", code: "+233", shortcode: "GH" },
  { country: "Gibraltar", code: "+350", shortcode: "GI" },
  { country: "Greece", code: "+30", shortcode: "GR" },
  { country: "Greenland", code: "+299", shortcode: "GL" },
  { country: "Grenada", code: "+1 473", shortcode: "GD" },
  { country: "Guadeloupe", code: "+590", shortcode: "GP" },
  { country: "Guam", code: "+1 671", shortcode: "GU" },
  { country: "Guatemala", code: "+502", shortcode: "GT" },
  { country: "Guinea", code: "+224", shortcode: "GN" },
  { country: "Guinea-Bissau", code: "+245", shortcode: "GW" },
  { country: "Guyana", code: "+595", shortcode: "GY" },
  { country: "Haiti", code: "+509", shortcode: "HT" },
  { country: "Honduras", code: "+504", shortcode: "HN" },
  { country: "Hungary", code: "+36", shortcode: "HU" },
  { country: "Iceland", code: "+354", shortcode: "IS" },
  { country: "India", code: "+91", shortcode: "IN" },
  { country: "Indonesia", code: "+62", shortcode: "ID" },
  { country: "Iraq", code: "+964", shortcode: "IQ" },
  { country: "Ireland", code: "+353", shortcode: "IE" },
  { country: "Israel", code: "+972", shortcode: "IL" },
  { country: "Italy", code: "+39", shortcode: "IT" },
  { country: "Jamaica", code: "+1 876", shortcode: "JM" },
  { country: "Japan", code: "+81", shortcode: "JP" },
  { country: "Jordan", code: "+962", shortcode: "JO" },
  { country: "Kazakhstan", code: "+7 7", shortcode: "KZ" },
  { country: "Kenya", code: "+254", shortcode: "KE" },
  { country: "Kiribati", code: "+686", shortcode: "KI" },
  { country: "Kuwait", code: "+965", shortcode: "KW" },
  { country: "Kyrgyzstan", code: "+996", shortcode: "KG" },
  { country: "Latvia", code: "+371", shortcode: "LV" },
  { country: "Lebanon", code: "+961", shortcode: "LB" },
  { country: "Lesotho", code: "+266", shortcode: "LS" },
  { country: "Liberia", code: "+231", shortcode: "LR" },
  { country: "Liechtenstein", code: "+423", shortcode: "LI" },
  { country: "Lithuania", code: "+370", shortcode: "LT" },
  { country: "Luxembourg", code: "+352", shortcode: "LU" },
  { country: "Madagascar", code: "+261", shortcode: "MG" },
  { country: "Malawi", code: "+265", shortcode: "MW" },
  { country: "Malaysia", code: "+60", shortcode: "MY" },
  { country: "Maldives", code: "+960", shortcode: "MV" },
  { country: "Mali", code: "+223", shortcode: "ML" },
  { country: "Malta", code: "+356", shortcode: "MT" },
  { country: "Marshall Islands", code: "+692", shortcode: "MH" },
  { country: "Martinique", code: "+596", shortcode: "MQ" },
  { country: "Mauritania", code: "+222", shortcode: "MR" },
  { country: "Mauritius", code: "+230", shortcode: "MU" },
  { country: "Mayotte", code: "+262", shortcode: "YT" },
  { country: "Mexico", code: "+52", shortcode: "MX" },
  { country: "Monaco", code: "+377", shortcode: "MC" },
  { country: "Mongolia", code: "+976", shortcode: "MN" },
  { country: "Montenegro", code: "+382", shortcode: "ME" },
  { country: "Montserrat", code: "+1664", shortcode: "MS" },
  { country: "Morocco", code: "+212", shortcode: "MA" },
  { country: "Myanmar", code: "+95", shortcode: "MM" },
  { country: "Namibia", code: "+264", shortcode: "NA" },
  { country: "Nauru", code: "+674", shortcode: "NR" },
  { country: "Nepal", code: "+977", shortcode: "NP" },
  { country: "Netherlands", code: "+31", shortcode: "NL" },
  { country: "Netherlands Antilles", code: "+599", shortcode: "AN" },
  { country: "New Caledonia", code: "+687", shortcode: "NC" },
  { country: "New Zealand", code: "+64", shortcode: "NZ" },
  { country: "Nicaragua", code: "+505", shortcode: "NI" },
  { country: "Niger", code: "+227", shortcode: "NE" },
  { country: "Nigeria", code: "+234", shortcode: "NG" },
  { country: "Niue", code: "+683", shortcode: "NU" },
  { country: "Norfolk Island", code: "+672", shortcode: "NF" },
  { country: "Northern Mariana Islands", code: "+1 670", shortcode: "MP" },
  { country: "Norway", code: "+47", shortcode: "NO" },
  { country: "Oman", code: "+968", shortcode: "OM" },
  { country: "Pakistan", code: "+92", shortcode: "PK" },
  { country: "Palau", code: "+680", shortcode: "PW" },
  { country: "Panama", code: "+507", shortcode: "PA" },
  { country: "Papua New Guinea", code: "+675", shortcode: "PG" },
  { country: "Paraguay", code: "+595", shortcode: "PY" },
  { country: "Peru", code: "+51", shortcode: "PE" },
  { country: "Philippines", code: "+63", shortcode: "PH" },
  { country: "Poland", code: "+48", shortcode: "PL" },
  { country: "Portugal", code: "+351", shortcode: "PT" },
  { country: "Puerto Rico", code: "+1 939", shortcode: "PR" },
  { country: "Qatar", code: "+974", shortcode: "QA" },
  { country: "Romania", code: "+40", shortcode: "RO" },
  { country: "Rwanda", code: "+250", shortcode: "RW" },
  { country: "Samoa", code: "+685", shortcode: "WS" },
  { country: "San Marino", code: "+378", shortcode: "SM" },
  { country: "Saudi Arabia", code: "+966", shortcode: "SA" },
  { country: "Senegal", code: "+221", shortcode: "SN" },
  { country: "Serbia", code: "+381", shortcode: "RS" },
  { country: "Seychelles", code: "+248", shortcode: "SC" },
  { country: "Sierra Leone", code: "+232", shortcode: "SL" },
  { country: "Singapore", code: "+65", shortcode: "SG" },
  { country: "Slovakia", code: "+421", shortcode: "SK" },
  { country: "Slovenia", code: "+386", shortcode: "SI" },
  { country: "Solomon Islands", code: "+677", shortcode: "SB" },
  { country: "South Africa", code: "+27", shortcode: "ZA" },
  {
    country: "South Georgia and the South Sandwich Islands",
    code: "+500",
    shortcode: "GS",
  },
  { country: "Spain", code: "+34", shortcode: "ES" },
  { country: "Sri Lanka", code: "+94", shortcode: "LK" },
  { country: "Sudan", code: "+249", shortcode: "SD" },
  { country: "Suriname", code: "+597", shortcode: "SR" },
  { country: "Swaziland", code: "+268", shortcode: "SZ" },
  { country: "Sweden", code: "+46", shortcode: "SE" },
  { country: "Switzerland", code: "+41", shortcode: "CH" },
  { country: "Tajikistan", code: "+992", shortcode: "TJ" },
  { country: "Thailand", code: "+66", shortcode: "TH" },
  { country: "Togo", code: "+228", shortcode: "TG" },
  { country: "Tokelau", code: "+690", shortcode: "TK" },
  { country: "Tonga", code: "+676", shortcode: "TO" },
  { country: "Trinidad and Tobago", code: "+1 868", shortcode: "TT" },
  { country: "Tunisia", code: "+216", shortcode: "TN" },
  { country: "Turkey", code: "+90", shortcode: "TR" },
  { country: "Turkmenistan", code: "+993", shortcode: "TM" },
  { country: "Turks and Caicos Islands", code: "+1 649", shortcode: "TC" },
  { country: "Tuvalu", code: "+688", shortcode: "TV" },
  { country: "Uganda", code: "+256", shortcode: "UG" },
  { country: "Ukraine", code: "+380", shortcode: "UA" },
  { country: "United Kingdom", code: "+44", shortcode: "GB" },
  { country: "United States", code: "+1", shortcode: "US" },
  { country: "Uruguay", code: "+598", shortcode: "UY" },
  { country: "Uzbekistan", code: "+998", shortcode: "UZ" },
  { country: "Vanuatu", code: "+678", shortcode: "VU" },
  { country: "Wallis and Futuna", code: "+681", shortcode: "WF" },
  { country: "Yemen", code: "+967", shortcode: "YE" },
  { country: "Zambia", code: "+260", shortcode: "ZM" },
  { country: "Zimbabwe", code: "+263", shortcode: "ZW" },
  { country: "land Islands", code: "" },
  { country: "Antarctica", code: null },
  { country: "Bolivia, Plurinational State of", code: "+591", shortcode: "BO" },
  { country: "Brunei Darussalam", code: "+673", shortcode: "BN" },
  { country: "Cocos (Keeling) Islands", code: "+61", shortcode: "CC" },
  {
    country: "Congo, The Democratic Republic of the",
    code: "+243",
    shortcode: "CD",
  },
  { country: "Cote d'Ivoire", code: "+225", shortcode: "CI" },
  { country: "Falkland Islands (Malvinas)", code: "+500", shortcode: "FK" },
  { country: "Guernsey", code: "+44", shortcode: "XU" },
  { country: "Holy See (Vatican City State)", code: "+379", shortcode: "VA" },
  { country: "Hong Kong", code: "+852", shortcode: "HK" },
  { country: "Iran, Islamic Republic of", code: "+98", shortcode: "IR" },
  { country: "Isle of Man", code: "+44", shortcode: "XM" },
  { country: "Jersey", code: "+44", shortcode: "XJ" },
  {
    country: "Korea, Democratic People's Republic of",
    code: "+850",
    shortcode: "KP",
  },
  { country: "Korea, Republic of", code: "+82", shortcode: "KR" },
  {
    country: "Lao People's Democratic Republic",
    code: "+856",
    shortcode: "LA",
  },
  { country: "Libyan Arab Jamahiriya", code: "+218", shortcode: "LY" },
  { country: "Macao", code: "+853", shortcode: "MO" },
  {
    country: "Macedonia, The Former Yugoslav Republic of",
    code: "+389",
    shortcode: "MK",
  },
  { country: "Micronesia, Federated States of", code: "+691", shortcode: "FM" },
  { country: "Moldova, Republic of", code: "+373", shortcode: "MD" },
  { country: "Mozambique", code: "+258", shortcode: "MZ" },
  { country: "Palestinian Territory, Occupied", code: "+970", shortcode: "PS" },
  { country: "Pitcairn", code: "+872", shortcode: "PN" },
  { country: "Reunion", code: "+262", shortcode: "RE" },
  { country: "Russia", code: "+7", shortcode: "RU" },
  { country: "Saint Barthelemy", code: "+590", shortcode: "BL" },
  {
    country: "Saint Helena, Ascension and Tristan Da Cunha",
    code: "+290",
    shortcode: "SH",
  },
  { country: "Saint Kitts and Nevis", code: "+1 869", shortcode: "KN" },
  { country: "Saint Lucia", code: "+1 758", shortcode: "LC" },
  { country: "Saint Martin", code: "+590", shortcode: "MF" },
  { country: "Saint Pierre and Miquelon", code: "+508", shortcode: "PM" },
  {
    country: "Saint Vincent and the Grenadines",
    code: "+1 784",
    shortcode: "VC",
  },
  { country: "Sao Tome and Principe", code: "+239", shortcode: "ST" },
  { country: "Somalia", code: "+252", shortcode: "SO" },
  { country: "Svalbard and Jan Mayen", code: "+47", shortcode: "SJ" },
  { country: "Syrian Arab Republic", code: "+963", shortcode: "SY" },
  { country: "Taiwan, Province of China", code: "+886", shortcode: "TW" },
  { country: "Tanzania, United Republic of", code: "+255", shortcode: "TZ" },
  { country: "Timor-Leste", code: "+670", shortcode: "TP" },
  {
    country: "Venezuela, Bolivarian Republic of",
    code: "+58",
    shortcode: "VE",
  },
  { country: "Viet Nam", code: "+84", shortcode: "VN" },
  { country: "Virgin Islands, British", code: "+1 284", shortcode: "VG" },
  { country: "Virgin Islands, U.S.", code: "+1 340", shortcode: "VI" },
];

export const phoneCountryCodeSet = fromJS(phoneCountyCodeList).toSet();
