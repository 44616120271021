import { compose, createStore, applyMiddleware } from "redux";
import fp from "lodash/fp";
import ReactDOM from "react-dom";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import { batchedSubscribe } from "redux-batched-subscribe";
import promiseMiddleware from "redux-promise-middleware";
import getStoredState from "redux-persist/es/getStoredState";
import createPersistor from "redux-persist/es/createPersistor";
import { authMiddleware } from "../redux/ReduxAuthMiddleware";
import { errorMiddleware } from "../redux/ReduxErrorMiddleware";
import { getPersistConfig } from "../helpers/StatePersistor";
import { api } from "../api/shared/BaseApi";
import { reducerContext } from "../../shared/helpers/ReducerContext";

const compactObject = fp.omitBy(fp.isNil);
const getNilKeys = fp.flow(fp.pickBy(fp.isNil), fp.keys);

reducerContext.injectReducer("form", formReducer);

export const configureStore = state =>
  new Promise(resolve => {
    const composeEnhancers =
      process.env.NODE_ENV === "production"
        ? compose
        : // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const middlewareEnhancer = applyMiddleware(
      authMiddleware(),
      promiseMiddleware(),
      thunk.withExtraArgument(api),
      process.env.NODE_ENV === "production"
        ? errorMiddleware()
        : // eslint-disable-next-line global-require,no-undef
          require("redux-logger").createLogger({ collapsed: true }),
    );

    const storeEnhancer = composeEnhancers(
      middlewareEnhancer,
      batchedSubscribe(ReactDOM.unstable_batchedUpdates),
    );

    const persistConfig = getPersistConfig();

    getStoredState(persistConfig, (error, persistedState) => {
      const nilKeys = getNilKeys(persistedState);
      const cleanPersistedState = compactObject(persistedState);
      const nextState = error ? state : { ...state, ...cleanPersistedState };

      const reducer = reducerContext.combineReducers();
      const store = createStore(reducer, nextState, storeEnhancer);

      const persistor = createPersistor(store, persistConfig);

      if (nilKeys.length) {
        persistor.purge(nilKeys);
      }

      reducerContext.syncWithStore(store);

      resolve(store);
    });
  });
