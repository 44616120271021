import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";

const safeFromJS = fp.ary(1, fromJS);

export const createAsyncActionTypes = type => [
  `${type}_PENDING`,
  `${type}_FULFILLED`,
  `${type}_REJECTED`,
];

export function createActionReducer(actionType, initialState, reducer) {
  return (state = initialState, action) =>
    action.type === actionType ? reducer(state, action) : state;
}

export function createAsyncReducer(
  actionType,
  initialState,
  { pending, fulfilled, rejected },
) {
  const [PENDING, FULFILLED, REJECTED] = createAsyncActionTypes(actionType);
  const actionReducers = {
    [PENDING]: pending,
    [FULFILLED]: fulfilled,
    [REJECTED]: rejected,
  };

  return (state = initialState, action) => {
    if (fp.has(action.type, actionReducers)) {
      const reducer = actionReducers[action.type];

      if (fp.isFunction(reducer)) {
        return reducer(state, action);
      }
    }

    return state;
  };
}

export function createAsyncFlagReducer(actionType: string): Function {
  return createAsyncReducer(actionType, false, {
    pending: fp.stubTrue,
    fulfilled: fp.stubFalse,
    rejected: fp.stubFalse,
  });
}

export const getItem = (state, id) => state.getIn(["items", id]);
export const isItemFetching = (state, id) => state.hasIn(["fetching", id]);
export const isItemNotFound = (state, id) => state.hasIn(["notFound", id]);

export function createAsyncItemReducer(
  actionType: string,
  {
    keySelector = fp.get("meta.id"),
    payloadSelector = fp.flow(fp.get("payload.data"), safeFromJS),
    errorStatusSelector = fp.get("payload.status"),
  } = {},
): Function {
  return createAsyncReducer(
    actionType,
    Map({ items: Map(), failed: Set(), fetching: Set(), notFound: Set() }),
    {
      pending: (state, action) =>
        state.update("fetching", item => item.add(keySelector(action))),
      fulfilled: (state, action) => {
        const key = keySelector(action);

        return state
          .setIn(["items", key], payloadSelector(action))
          .update("failed", item => item.delete(key))
          .update("fetching", item => item.delete(key))
          .update("notFound", item => item.delete(key));
      },
      rejected: (state, action) => {
        const key = keySelector(action);

        return state
          .update("failed", item => item.add(key))
          .update("fetching", item => item.delete(key))
          .update("notFound", item =>
            errorStatusSelector(action) === 404 ? item.add(key) : item,
          );
      },
    },
  );
}
