import _ from "lodash";
// import { cloneDeep } from "lodash";
import { fromJS, Map } from "immutable";
import Cookies from "js-cookie";
import fp from "lodash/fp";
import { combineReducers } from "redux-immutable";
import { getSupplierLanguage, getUserLanguage } from "./ProfileReducer";
import { getMarketplaceDefaultLanguage } from "./MarketplaceReducer";
import {
  ARABIC_LANGUAGE,
  ENGLISH_LANGUAGE,
  FRENCH_LANGUAGE,
  HUNGARIAN_LANGUAGE,
  MONGOLIAN_LANGUAGE,
  RUSSIAN_LANGUAGE,
  SERBIAN_LANGUAGE,
  SPANISH_LANGUAGE,
  TURKISH_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../constants/LocaleTypes";
import { parseUrl } from "../../shared/helpers/UrlUtils";
import { injectReducer } from "../../shared/helpers/ReducerContext";
import {
  getAppName,
  getMarketplaceLanguage,
} from "../../shared/reducers/AppReducer";
import { SUPPLIER } from "../../server/constants/AppTypes";
import globalLocales from "./../messages/global_locales.json";

export const globalLocalesJson = globalLocales;
const getMergedAppLocale = (appLocale) => ({
  uz: { ...globalLocales.uz, ...appLocale.uz },
  en: { ...globalLocales.en, ...appLocale.en },
  ru: { ...globalLocales.ru, ...appLocale.ru },
  tr: { ...globalLocales.tr, ...appLocale.tr },
  fr: { ...globalLocales.fr, ...appLocale.fr },
  sr: { ...globalLocales.sr, ...appLocale.sr },
  mn: { ...globalLocales.mn, ...appLocale.mn },
  es: { ...globalLocales.es, ...appLocale.es },
  ar: { ...globalLocales.ar, ...appLocale.ar },
  hu: { ...globalLocales.hu, ...appLocale.hu },
});

const langFile = getMergedAppLocale(globalLocales);

const jsonObject = fromJS(langFile);

const selector = injectReducer(
  "localisation",
  combineReducers({
    [ENGLISH_LANGUAGE]: () => jsonObject.get(ENGLISH_LANGUAGE) || {},
    [RUSSIAN_LANGUAGE]: () => jsonObject.get(RUSSIAN_LANGUAGE) || {},
    [UZBEK_LANGUAGE]: () => jsonObject.get(UZBEK_LANGUAGE) || {},
    [TURKISH_LANGUAGE]: () => jsonObject.get(TURKISH_LANGUAGE) || {},
    [FRENCH_LANGUAGE]: () => jsonObject.get(FRENCH_LANGUAGE) || {},
    [SERBIAN_LANGUAGE]: () => jsonObject.get(SERBIAN_LANGUAGE) || {},
    [MONGOLIAN_LANGUAGE]: () => jsonObject.get(MONGOLIAN_LANGUAGE) || {},
    [SPANISH_LANGUAGE]: () => jsonObject.get(SPANISH_LANGUAGE) || {},
    [ARABIC_LANGUAGE]: () => jsonObject.get(ARABIC_LANGUAGE) || {},
    [HUNGARIAN_LANGUAGE]: () => jsonObject.get(HUNGARIAN_LANGUAGE) || {},
  }),
);

export const getCurrentLanguage = (state) => {
  let langCode;

  if (state) {
    const appName = getAppName(state);
    if (!_.isEmpty(appName)) {
      if (appName === SUPPLIER) {
        langCode = getSupplierLanguage(state);
      } else {
        langCode = getUserLanguage(state);
      }
      if (!langCode) {
        const url = parseUrl(window.location);
        if (url.query.lang) {
          langCode = url.query.lang;
          Cookies.set("language", langCode);
        } else {
          langCode = Cookies.get("language");

          if (!langCode) {
            langCode =
              getMarketplaceLanguage(state) ||
              getMarketplaceDefaultLanguage(state);
          }
        }
      }
    }
  }

  if (!langCode) {
    langCode = Cookies.get("language");
  }
  return langCode;
};

export function getLanguage(state, text, defaultText) {
  return selector(state).getIn([getCurrentLanguage, text], defaultText);
}

export function getMessage(state, code, defaultText) {
  if (!code) {
    return defaultText;
  }
  const message = selector(state).getIn(
    [getCurrentLanguage(state), code.toLowerCase()],
    defaultText,
  );
  if (code && !defaultText && !message) {
    const clean = fp.flow(
      fp.replace(new RegExp("_", "ig"), " "),
      fp.capitalize,
    );
    return clean(code);
  }

  return message;
}

export function getMessages(state) {
  return selector(state).get(getCurrentLanguage(state), Map());
}

export function getIsRTL(state) {
  const currentLanguage = getCurrentLanguage(state);
  const rtlLanguages = [ARABIC_LANGUAGE];
  return rtlLanguages.indexOf(currentLanguage) > -1;
}
