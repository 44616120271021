import React from "react";
import _ from "lodash";
import Immutable, { is, fromJS, Iterable } from "immutable";
import fp from "lodash/fp";

export const isImmutable = (value) => Iterable.isIterable(value);

export const getSize = (value) =>
  isImmutable(value) ? value.size : fp.size(value);

export const isEmpty = (value) =>
  isImmutable(value) ? value.isEmpty() : fp.isEmpty(value);

export const hasValue = (target, path) =>
  !isImmutable(target)
    ? _.has(target, path)
    : fp.isArray(path)
    ? target.hasIn(path)
    : target.has(path);

export const getValue = (target, path, notSetValue) =>
  isImmutable(target)
    ? fp.isArray(path)
      ? target.getIn(path, notSetValue)
      : target.get(path, notSetValue)
    : _.get(target, path, notSetValue);

export const getHash = (value) =>
  isImmutable(value) ? value.hashCode() : fromJS([value]).hashCode();

export const toJS = (value) => (isImmutable(value) ? value.toJS() : value);

export const toArray = fp.flow(toJS, fp.toArray);
export const toPlainObject = fp.flow(toJS, fp.toPlainObject);

const isEqualDataCustomizer = (a, b) =>
  isImmutable(a) || isImmutable(b)
    ? is(a, b)
    : React.isValidElement(a) || React.isValidElement(b)
    ? a === b
    : undefined;

export const createDataComparator = fp.isEqualWith;

export const isEqualData = createDataComparator(isEqualDataCustomizer);

export const isEqualDataIn = (path) => (a, b) => {
  const aValue = getValue(a, path);
  const bValue = getValue(b, path);
  return isEqualData(aValue, bValue);
};

export const isEqualDataKeys = (keys) => (a, b) => {
  const aValue = fp.pick(keys, a);
  const bValue = fp.pick(keys, b);
  return isEqualData(aValue, bValue);
};

export const isShallowEqual = createDataComparator(
  (a, b, index, object, other, stack) =>
    stack
      ? isImmutable(a) || isImmutable(b)
        ? is(a, b)
        : a === b
      : isEqualDataCustomizer(a, b),
);

export const isEqualWithoutFunctions = createDataComparator(
  (a, b) =>
    (fp.isFunction(a) && fp.isFunction(b)) || isEqualDataCustomizer(a, b),
);

export const mapResponseData = fp.flow(
  fp.flow(
    (response) => fromJS(response),
    (response) =>
      fromJS({
        pending: response.get("pending", false),
        payload: response.getIn(["payload", "data"], null),
      }),
  ),
);

export const hooksMapResponseList = fp.flow(fp.get("data"), Immutable.fromJS);
const latUp = [
  "F",
  ",",
  "D",
  "U",
  "L",
  "T",
  "`",
  ";",
  "P",
  "B",
  "Q",
  "R",
  "K",
  "V",
  "Y",
  "J",
  "G",
  "H",
  "C",
  "N",
  "E",
  "A",
  "[",
  "W",
  "X",
  "I",
  "O",
  "]",
  "S",
  "M",
  "'",
  ".",
  "Z",
  "0000",
];
const rusUp = [
  "А",
  "Б",
  "В",
  "Г",
  "Д",
  "Е",
  "Ё",
  "Ж",
  "З",
  "И",
  "Й",
  "К",
  "Л",
  "М",
  "Н",
  "О",
  "П",
  "Р",
  "С",
  "Т",
  "У",
  "Ф",
  "Х",
  "Ц",
  "Ч",
  "Ш",
  "Щ",
  "Ъ",
  "Ы",
  "Ь",
  "Э",
  "Ю",
  "Я",
  "0000",
];
export const kirilToLatinByKeyboardPlace = (value) => {
  if (value) {
    const str = value.toUpperCase().split("");
    const newStr = [];
    str.forEach((character) => {
      const foundIndex = rusUp.findIndex((v) => v === character);
      if (foundIndex >= 0) newStr.push(latUp[foundIndex]);
      else newStr.push(character);
    });
    return newStr.join("");
  }
  return value;
};
export const hideTextByKeyboardPlace = (value) => {
  if (value) {
    const str = value.toUpperCase().split("");
    const newStr = [];
    str.forEach((character) => {
      const foundIndexLatin = latUp.findIndex((v) => v === character);
      const foundIndexKiril = rusUp.findIndex((v) => v === character);
      if (foundIndexLatin >= 0) newStr.push(latUp[foundIndexLatin + 1]);
      else if (foundIndexKiril >= 0) newStr.push(latUp[foundIndexKiril + 1]);
      else newStr.push(character);
    });
    return newStr.join("");
  }
  return value;
};

export const encodeTextByKeyboardPlace = (value) => {
  if (value) {
    const str = value.toUpperCase().split("");
    const newStr = [];
    str.forEach((character) => {
      const foundIndexLatin = latUp.findIndex((v) => v === character);
      const foundIndexKiril = rusUp.findIndex((v) => v === character);
      if (foundIndexLatin >= 0) newStr.push(latUp[foundIndexLatin - 1]);
      else if (foundIndexKiril >= 0) newStr.push(latUp[foundIndexKiril - 1]);
      else newStr.push(character);
    });
    return newStr.join("");
  }
  return value;
};

/* eslint-disable */
export const isNode = (o) =>
  typeof Node === "object"
    ? o instanceof Node
    : o &&
      typeof o === "object" &&
      typeof o.nodeType === "number" &&
      typeof o.nodeName === "string";

export const isElement = (o) =>
  typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
      typeof o === "object" &&
      o !== null &&
      o.nodeType === 1 &&
      typeof o.nodeName === "string";
