import { isDate, isFuture, addSeconds } from "date-fns";
import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux-immutable";
import { decodeJWT } from "../helpers/JwtUtils";
import { injectReducer } from "../helpers/ReducerContext";
import { setToken } from "../../client/helpers/CookieHelper";
import {
  createAsyncReducer,
  createActionReducer,
  createAsyncActionTypes,
  createAsyncFlagReducer,
} from "../../client/helpers/ReducerFactory";

export const SIGN_IN = "AUTH/SIGN_IN";
export const [, SIGN_IN_FULFILLED] = createAsyncActionTypes(SIGN_IN);

export const SIGN_UP = "AUTH/SIGN_UP";
export const [, SIGN_UP_FULFILLED] = createAsyncActionTypes(SIGN_UP);

export const SIGN_OUT = "AUTH/SIGN_OUT";

export const FACEBOOK_SIGN_IN = "AUTH/FACEBOOK_SIGN_IN";
const CLEAN_FACEBOOK_SIGN_IN = "AUTH/CLEAN_FACEBOOK_SIGN_IN";

export const [, FACEBOOK_SIGN_IN_FULFILLED] = createAsyncActionTypes(
  FACEBOOK_SIGN_IN,
);

export const INIT_TOKEN = "AUTH/INIT_TOKEN";

export const RESET_PASSWORD = "AUTH/RESET_PASSWORD";
export const UPDATE_PASSWORD = "AUTH/UPDATE_PASSWORD";

export const REQUEST_CODE = "AUTH/REQUEST_CODE";
export const CONFIRM_CODE = "AUTH/CONFIRM_CODE";

const PERSIST_TOKEN = "AUTH/PERSIST_TOKEN";

// const excludeUserRoles = fp.flow(fp.get("roles") || [], fp.split(","));
//
// const checkUserAuthority = (payload, authority) => {
//   const roles = excludeUserRoles(payload);
//   switch (authority) {
//     case ROLE_ADMIN:
//       return roles.includes(ROLE_ADMIN) || roles.includes(ROLE_ADMIN_VIEWER);
//
//     default:
//       return roles.includes(authority);
//   }
// };

const reducer = combineReducers({
  token: (
    state = Map({
      payload: null,
      expiresAt: null,
      validating: false,
    }),
    action,
  ) => {
    switch (action.type) {
      case INIT_TOKEN: {
        const payload = decodeJWT(action.payload);

        return state.merge(
          fromJS({
            payload,
            validated: true,
            validating: false,
            expiresAt: addSeconds(new Date(0), payload.exp),
          }),
        );
      }

      default:
        return state;
    }
  },

  tokenPersisting: createActionReducer(PERSIST_TOKEN, false, fp.stubTrue),

  signUp: createAsyncReducer(
    SIGN_UP,
    Map({ pending: false, existUsernames: Set() }),
    {
      pending(state) {
        return state.set("pending", true);
      },
      fulfilled(state) {
        return state.set("pending", false);
      },
      rejected(state, { meta: { username }, payload: { code } }) {
        return state
          .set("pending", false)
          .update("existUsernames", item =>
            code === "userexists" ? item.add(username) : item,
          );
      },
    },
  ),

  signingIn: createAsyncFlagReducer(SIGN_IN),

  signingOut: createAsyncFlagReducer(SIGN_OUT),

  facebookSignIn: reduceReducers(
    createAsyncReducer(
      FACEBOOK_SIGN_IN,
      Map({ account: null, pending: false }),
      {
        pending(state) {
          return state.merge({ account: null, pending: true });
        },
        fulfilled(state, action) {
          const mapUser = fp.flow(fp.get("payload.data.user"), fromJS);

          return state.merge({ pending: false, account: mapUser(action) });
        },
        rejected(state) {
          return state.merge({ account: null, pending: false });
        },
      },
    ),
    (state, action) =>
      action.type === CLEAN_FACEBOOK_SIGN_IN
        ? state.set("account", null)
        : state,
  ),

  codeRequesting: createAsyncFlagReducer(REQUEST_CODE),
  codeConfirming: createAsyncFlagReducer(CONFIRM_CODE),
  passwordUpdating: createAsyncFlagReducer(UPDATE_PASSWORD),
  passwordResetting: createAsyncFlagReducer(RESET_PASSWORD),
});

const selector = injectReducer("auth", reducer);

export function getTokenUserId(state) {
  return selector(state).getIn(["token", "payload", "userId"]);
}

/**
 * @deprecated
 */
export function getTokenCustomerId(state) {
  return selector(state).getIn(["token", "payload", "customerId"]);
}

export function isTokenValid(state) {
  const expiresAt = selector(state).getIn(["token", "expiresAt"]);
  return isDate(expiresAt) ? isFuture(expiresAt) : false;
}

export function isTokenPersisting(state) {
  return selector(state).get("tokenPersisting");
}

export function isSigningUp(state) {
  return selector(state).getIn(["signUp", "pending"]);
}

export function isSigningIn(state) {
  return selector(state).get("signingIn");
}

export function isSigningOut(state) {
  return selector(state).get("signingOut");
}

export function getFacebookAccount(state) {
  return selector(state).getIn(["facebookSignIn", "account"]);
}

export function isFacebookSigningIn(state) {
  return selector(state).getIn(["facebookSignIn", "pending"]);
}

export function isCodeRequesting(state) {
  return selector(state).get("codeRequesting");
}

export function isCodeConfirming(state) {
  return selector(state).get("codeConfirming");
}

export function isPasswordResetPending(state) {
  return selector(state).get("passwordResetting");
}

export function isPasswordUpdatePending(state) {
  return selector(state).get("passwordUpdating");
}

export function cleanFacebookSignIn() {
  return { type: CLEAN_FACEBOOK_SIGN_IN };
}

export const persistToken = token => {
  setToken(token);

  return { type: PERSIST_TOKEN };
};
