import _ from "lodash";

export function obtainElement(
  id: string,
  tag: string,
  parentElement?: Element,
): Element {
  const parent: Element = parentElement || document.body;
  const elementTag = tag || "div";

  let element = document.getElementById(id);

  if (!element) {
    element = document.createElement(elementTag);
    element.setAttribute("id", id);

    parent.insertBefore(element, document.getElementById("content"));
  }

  return element;
}

export function deleteElement(idOrElement: string | Element): void {
  const element = !_.isString(idOrElement)
    ? idOrElement
    : document.getElementById(idOrElement);

  if (element && element.parentNode) {
    element.parentNode.removeChild(element);
  }
}
