import { blue, blueGrey, green, orange } from "@material-ui/core/colors";

export const [
  button,
  icon,
  navbar,
  chip1,
  chip2,
  chip3,
  text,
  background,
  white,
] = [
  blue[700],
  blueGrey[600],
  "#f1f4f6",
  green[500],
  orange[500],
  "#EB5757",
  blueGrey[900],
  "#F4F6F8",
  "#FFFFFF",
];

export const mapTheme = (theme) => ({
  ...theme,
  palette: {
    primary1Color: theme.palette.primary1Color,
    primary2Color: theme.palette.primary2Color,
    primary3Color: theme.palette.primary3Color,
    primary4Color: theme.palette.primary4Color,

    accent1Color: theme.palette.accent1Color,
    accent2Color: theme.palette.accent2Color,
    accent3Color: theme.palette.accent3Color,
    accent4Color: theme.palette.accent4Color,
    buttonColor: theme.palette.buttonColor,
    textColor: theme.palette.textColor,
    canvasColor: theme.palette.canvasColor,
    borderColor: theme.palette.borderColor,
    disabledColor: theme.palette.disabledColor,
    pickerHeaderColor: theme.palette.pickerHeaderColor,
    clockCircleColor: theme.palette.clockCircleColor,
    shadowColor: theme.palette.shadowColor,
    newButtonColor1Hover: theme.palette.newButtonColor1Hover,
    newButtonColor1: theme.palette.newButtonColor1,
    headerInfoColor: theme.palette.headerInfoColor,

    primary: {
      main: theme.palette.primary1Color,
      contrastText: theme.palette.textColor,
    },
    secondary: {
      main: theme.palette.accent1Color,
      contrastText: theme.palette.alternateTextColor,
    },

    tonalOffset: 0.2,
    alternateTextColor: theme.palette.alternateTextColor,
    appBarTextColor: theme.palette.appBarTextColor,
    activeTextColor: theme.palette.activeTextColor,
  },
  appBar: {
    textColor: text,
    background: navbar,
  },
  tabs: {
    textColor: text,
    selectedTextColor: text,
    background: navbar,
  },
  icon: {
    color: white,
    background: icon,
  },
  active: {
    tab: navbar,
  },
  overrides: {
    MuiTab: {
      root: {
        backgroundColor: theme.palette.primary1Color,
        height: 54,
      },
      wrapper: {
        color: theme.palette.alternateTextColor,
      },
    },
    MuiDialogContent: {
      // root: {
      //   color: "#ffffff",
      //   "& .MuiPickersBasePicker-pickerView .MuiPickersDay-daySelected span": {
      //     color: "#ffffff",
      //   },
      // },
    },
    MuiDialogTitle: {
      // root: {
      //   color: "#ffffff",
      // },
    },
    MuiToolbar: {
      // root: {
      //   color: "#ffffff",
      //   "& .MuiPickersToolbarText-toolbarTxt": {
      //     color: "#ffffffaa",
      //   },
      //   "& .MuiPickersToolbarText-toolbarBtnSelected": {
      //     color: "#ffffff",
      //   },
      // },
    },
    MuiIconButton: {
      // colorPrimary: "#ffffff",
    },
    "&$checked": {
      color: button,
    },
    MuiSelect: {
      outlined: {
        "&:focus": {
          backgroundColor: "#FFF",
        },
      },
    },
  },
});
