import fp from "lodash/fp";
import { base64ToObject } from "./CryptoUtils";
import { fromBase64 } from "../../shared/helpers/DataSerializer";

export const decodeJWT = fp.flow(
  fp.trim,
  fp.split("."),
  fp.get(1),
  fp.trim,
  base64ToObject,
);

export const decodeUserJWT = fp.flow(fp.trim, fromBase64);

export const getJWTUserId = fp.flow(decodeJWT, fp.get("userId"));
