import { Url, parse, format } from "url";
import fp from "lodash/fp";

type Location = string | Url | Object;

export const formatUrl: (url: string | Url | Object) => string =
  fp.flow(format);

export const parseUrl: (url: string | Url | Object) => Object = fp.flow(
  format,
  (v) => parse(v, true),
  fp.toPlainObject,
  fp.pick([
    "protocol",
    "slashes",
    "auth",
    "host",
    "port",
    "hostname",
    "hash",
    "query",
    "pathname",
    "path",
    "href",
  ]),
);

export const compareLocations: (
  a: string | Url | Object,
  b: string | Url | Object,
) => boolean = fp.flow(fp.rest(fp.map(parseUrl)), fp.spread(fp.isEqual));

const updateLocationProperty = (
  url: Location,
  property: string,
  updater: any,
): string => {
  const location = parseUrl(url);

  location[property] = !fp.isFunction(updater)
    ? updater
    : updater(location[property]);

  return format(location);
};

export const updatePathname = (
  url: Location,
  updater: string | ((value: string) => string),
): string => updateLocationProperty(url, "pathname", updater);

export const updateQuery = (
  url: Location,
  updater: Object | ((value: Object) => Object),
): string => updateLocationProperty(url, "query", updater);

export const updateHash = (
  url: Location,
  updater: string | ((value: string) => string),
): string => updateLocationProperty(url, "hash", updater);

export const combineCleanQuery = fp.rest(
  fp.flow(fp.flattenDeep, fp.assignAll, fp.omitBy(fp.isNil)),
);
