import _ from "lodash";
import {
  parse,
  isDate,
  isValid,
  getMonth,
  setHours,
  setMinutes,
  formatDistance,
  formatDistanceStrict,
  toDate,
} from "date-fns";
import moment from "moment";

import fp from "lodash/fp";
// eslint-disable-next-line import/no-internal-modules
import ru from "date-fns/locale/ru";

export const isValidDate = fp.overEvery([isDate, isValid]);

// export const safeParseDate = fp.flow(
//   (v) => (fp.isNumber(v) || fp.isString(v) ? toDate(v) : v),
//   v => (isValidDate(v) ? v : null),
// );

export const safeParseDate = fp.flow(
  v => (fp.isNumber(v) || fp.isString(v) ? moment(v).toDate() : v),
  v => (isValidDate(v) ? v : null),
);

export const minutesToDate = fp.flow(
  fp.toFinite,
  m => Date.UTC(0, 0, 0, 0, m),
  t => new Date(t),
);
export const dateToMinutes = fp.flow(
  toDate,
  d => 60 * d.getUTCHours() + d.getUTCMinutes(),
);

const TIME_STRING_REG_EXP = /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/;
const UNLIMITED_TIME_STRING_REG_EXP = /^\d+:[0-5]\d(:[0-5]\d)?$/;

export const isValidTimeString = (value: string, unlimited: boolean = false) =>
  unlimited
    ? UNLIMITED_TIME_STRING_REG_EXP.test(value)
    : TIME_STRING_REG_EXP.test(value);

export const shortTimeToDate = time => {
  if (isValidTimeString(time)) {
    const timeArray = _.split(time, ":");
    const date = setHours(new Date(), timeArray[0]);

    return setMinutes(date, timeArray[1]);
  }

  return time;
};

export const formattedDuration = (
  seconds: number,
  lang: string = "en",
  strict = true,
): string => {
  const langConfig = lang === "ru" ? { locale: ru } : {};
  const distanceFn = strict ? formatDistanceStrict : formatDistance;
  return distanceFn(0, seconds * 1000, { includeSeconds: true, ...langConfig });
};

const isCurrentMonth = fp.flow(parse, getMonth, fp.eq(getMonth(new Date())));

export const filterCurrentMonthReports = fp.filter(
  fp.flow(fp.get("date_num"), isCurrentMonth),
);
