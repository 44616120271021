import fp from "lodash/fp";
import loadScript from "load-script";
import { parseUrl } from "../../shared/helpers/UrlUtils";
import canUseDom from "../../shared/helpers/canUseDom";

const scriptUrl = "https://www.google-analytics.com/analytics.js";

const ga = getGa(scriptUrl);

function getGa(url) {
  if (canUseDom) {
    window.GoogleAnalyticsObject = "ga";

    if (!window.ga) {
      window.ga = (...args) => window.ga.q.push(args);
      window.ga.q = [];

      loadScript(url);
    }

    window.ga.l = new Date().getTime();

    return (...args) => window.ga(...args);
  }

  return fp.noop;
}

const requirePlugins = fp.forEach(plugin => {
  ga("require", plugin);
});

export const initTrackers = (options: {
  app: string,
  gaPlugins: string[],
  gaTrackingID: string,
  gaTracking2ID: string,
}) => {
  if (!options) {
    throw new TypeError(
      `'options' expected to be an object, not ${typeof gaTrackingID}`,
    );
  }

  if (!options.gaTrackingID) {
    throw new TypeError(
      `'options.gaTrackingID' expected to be a string, not ${typeof options.gaTrackingID}`,
    );
  }

  const createOptions = {
    cookieDomain: "auto",
    trackingId: options.gaTrackingID,
  };
  const url = parseUrl(window.location);

  if (url.query.ga_client_id) {
    createOptions.clientId = url.query.ga_client_id;
  }

  ga("create", createOptions);
  ga("create", {
    ...createOptions,
    trackingId: options.gaTracking2ID,
    name: "tracker2",
  });

  ga("require", "ec");
  ga("tracker2.require", "ec");

  if (options.app) {
    ga("set", "appName", options.app);
    ga("tracker2.set", "appName", options.app);
  }

  requirePlugins(options.gaPlugins);
};

export const setUserId = userId => {
  ga("set", "userId", userId);
  ga("tracker2.set", "userId", userId);
};

export const sendEvent = (options: {
  hitType: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  eventValue: number,
  timeout: number,
}) =>
  new Promise(resolve => {
    const opt = {
      transport: "beacon",
      hitType: options.hitType,
      eventCategory: options.eventCategory,
      eventAction: options.eventAction,
      eventLabel: options.eventLabel,
      eventValue: options.eventValue,
      hitCallback() {
        resolve();
      },
    };
    ga("send", opt);
    ga("tracker2.send", opt);

    setTimeout(() => resolve(), options.timeout || 3000);
  });

export const ecPurchase = (options: {
  id: string,
  affiliation: string,
  revenue: number,
  tax: number,
  shipping: number,
  coupon: string,
}) =>
  ga("ec:setAction", "purchase", {
    id: options.id,
    affiliation: options.affiliation,
    revenue: options.revenue,
    tax: options.tax,
    shipping: options.shipping,
    coupon: options.coupon,
  });

const getServiceCharge = fp.flow(
  fp.get("charge_items"),
  fp.find(fp.matches({ charge_type: "service" })),
  fp.get("charge"),
  fp.toFinite,
);

const getPromoCode = fp.get(["promo", "code"]);

export const trackOrderCreate = fp.curry((app, order) => {
  ecPurchase({
    id: order.order_number,
    coupon: getPromoCode(order),
    revenue: getServiceCharge(order),
  });

  return sendEvent({
    hitType: "event",
    eventCategory: "Order",
    eventAction: `${app} Complete`,
  });
});

export const trackPageView = (location, title, appName) => {
  ga("tracker2.send", {
    transport: "beacon",
    hitType: "pageview",
    page: location.pathname,
    title: `${appName} App - ${title}`,
  });
};
