import { isFuture } from "date-fns";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import localforage from "localforage";
import createTransform from "redux-persist/es/createTransform";
import { getValue, hasValue } from "./DataUtils";
import { toJSON, fromJSON } from "../../shared/helpers/DataSerializer";

const appMigrationTransformer = (version) =>
  createTransform(fp.identity, (state) => {
    if (!hasValue(state, "appVersion")) {
      return state;
    }

    return getValue(state, "appVersion") === version ? state : undefined;
  });

const expirationTransformer = () =>
  createTransform(fp.identity, (state) => {
    if (!hasValue(state, "expiresAt")) {
      return state;
    }

    return isFuture(getValue(state, "expiresAt")) ? state : undefined;
  });

const omitKeysTransformer = () =>
  createTransform(fp.identity, (state) => {
    if (Map.isMap(state) && Set.isSet(state.get("omit"))) {
      return state.withMutations((x) => {
        state.get("omit").forEach((key) => {
          x.delete(key);
        });
      });
    }

    return state;
  });

const immutableTransformer = () => createTransform(toJSON, fromJSON);

const persistWhitelist = [
  "country",
  "order-sorting@3.0.0",
  "order-bin-validation@1.0.0",
  "order-inbound-sorting@1.0.0",
  "order-customs-inbound-sorting@1.0.0",
];

export const getPersistConfig = () => ({
  storage: localforage,
  whitelist: module.hot ? ["form", ...persistWhitelist] : persistWhitelist,
  transforms: [
    omitKeysTransformer(),
    appMigrationTransformer(process.env.APP_VERSION),
    expirationTransformer(),
    immutableTransformer(),
  ],
});
