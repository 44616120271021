import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sizeMe from "react-sizeme";
import { GoogleMap, ZoomControl } from "react-google-map-components";
import GoogleMapSizer from "./GoogleMapSizer";
import GoogleMapOptions from "./GoogleMapOptions";
import LinkButton from "../ui-core/LinkButton";
import { getMapsStream } from "../../helpers/GoogleMapsHelper";
import { getMarketplaceMapCenter } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SETTINGS_MAP_URL } from "../../constants/AdminPathConstants";
import {
  getAppName,
  getMapProviderKey,
} from "../../../shared/reducers/AppReducer";
import { ADMIN, CUSTOMER, SUPPLIER } from "../../../server/constants/AppTypes";

export const DUBAI_CENTER = { lat: 25.1, lng: 55.1922004 };
export const RIYADH_CENTER = { lat: 24.7136, lng: 46.6753 };
export const TASHKENT_CENTER = { lat: 41.2610674, lng: 69.2766249 };
const hasLocation = fp.flow(
  fp.over([fp.get("lat"), fp.get("lng")]),
  fp.every(Boolean),
);

const enhancer = compose(
  connect(state => ({
    baseCountryMapCenter: getMarketplaceMapCenter(state),
    mapProviderKey: getMapProviderKey(state),
    app: getAppName(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({ setLocation: PropTypes.func.isRequired }),
  sizeMe({ monitorWidth: true, monitorHeight: true }),
  useSheet({
    map: { height: "100%" },
    errorMessageForCustomer: {
      backgroundColor: "white",
      fontWeight: 500,
      padding: "15px 25px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "5px",
      left: "50%",
      maxWidth: "375px",
      position: "absolute",
      transform: "translateX(-50%)",
      width: "calc(100% - 10px)",
      margin: "0 auto",
      zIndex: 1,
      top: "30%",
    },
    errorMessage: {
      backgroundColor: "white",
      fontWeight: 500,
      padding: "15px 25px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "5px",
      left: "50%",
      maxWidth: "375px",
      position: "absolute",
      transform: "translateX(-50%)",
      width: "calc(100% - 10px)",
      margin: "0 auto",
      zIndex: 1,
      top: "40%",
    },
  }),
  mapPropsStream(propsStream =>
    propsStream.combineLatest(getMapsStream(), (props, maps) => ({
      ...props,
      maps,
    })),
  ),
);

GoogleMapWrapper.propTypes = {
  size: PropTypes.object,
  classes: PropTypes.object,

  maps: PropTypes.object,
  children: PropTypes.node,

  style: PropTypes.object,
  className: PropTypes.string,

  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  mapCenter: PropTypes.object,
  baseCountryMapCenter: PropTypes.object,

  hasZoomControl: PropTypes.bool,
  clickableIcons: PropTypes.bool,
  disableDoubleClickZoom: PropTypes.bool,

  onClick: PropTypes.func,
  onDragEnd: PropTypes.func,
  onZoomChanged: PropTypes.func,
  onCenterChanged: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setLocation: PropTypes.func,
  mapProviderKey: PropTypes.string,
  app: PropTypes.string,
  track: PropTypes.bool,
};

GoogleMapWrapper.defaultProps = {
  zoom: 10,

  hasZoomControl: true,
  clickableIcons: false,
  disableDoubleClickZoom: true,
  track: false,
};

function GoogleMapWrapper(props) {
  return (
    <GoogleMap
      maps={props.maps}
      zoom={props.zoom}
      center={
        hasLocation(props.mapCenter)
          ? props.mapCenter
          : props.baseCountryMapCenter
      }
      clickableIcons={props.clickableIcons}
      style={props.style}
      className={cx(props.classes.map, props.className)}
      disableDoubleClickZoom={props.disableDoubleClickZoom}
      onClick={props.onClick}
      onDragEnd={props.onDragEnd}
      onZoomChanged={props.onZoomChanged}
      onCenterChanged={props.onCenterChanged}
      onBoundsChanged={props.onBoundsChanged}
    >
      <GoogleMapOptions maxZoom={props.maxZoom} minZoom={props.minZoom} />

      {props.hasZoomControl && <ZoomControl />}

      <GoogleMapSizer maps={props.maps} size={props.size} />

      {!props.mapProviderKey &&
        props.app === ADMIN && (
          <div className={props.classes.errorMessage}>
            {props.getLocalisationMessage(
              "",
              "In order to use Map Services, billing must be enabled on your account, and all requests must include a valid API key. Once the API key purchased from preferred Map Service Provider it can be linked to your account",
            )}{" "}
            <LinkButton
              style={{
                color: "red",
              }}
              onClick={() => props.setLocation(SETTINGS_MAP_URL)}
            >
              {props.getLocalisationMessage("here", "here")}
            </LinkButton>
          </div>
        )}

      {!props.mapProviderKey &&
        (props.app === CUSTOMER || props.app === SUPPLIER) &&
        !props.track && (
          <div className={props.classes.errorMessageForCustomer}>
            {props.getLocalisationMessage(
              "",
              "Please contact your company administrator in order to use Map Services.",
            )}
          </div>
        )}

      {props.children}
    </GoogleMap>
  );
}

export default enhancer(GoogleMapWrapper);
