import _ from "lodash";
import fp from "lodash/fp";
import { toPlainObject } from "../../client/helpers/DataUtils";
import {
  formatDateToUrl,
  formatDateTimeToUrl,
  formatDateTimeFullUrl,
} from "../../client/helpers/FormatUtils";

const falsyToNull = v => v || null;

export const mapSeq = mapper =>
  fp.flow(
    fp.trim,
    fp.split(","),
    fp.map(mapper),
    fp.uniq,
    fp.compact,
    fp.join(","),
  );

const ID = fp.flow(fp.toInteger, fp.clamp(0, Infinity), falsyToNull);

const stringOr = fp.curry((notSetValue, value) => {
  const cleanValue = fp.trim(value);

  return cleanValue || notSetValue;
});

const string = stringOr(null);

const booleanOr = fp.curry((notSetValue, value) => {
  switch (value) {
    case true:
    case "true":
      return true;
    case false:
    case "false":
      return false;
    default:
      return notSetValue;
  }
});

export const types = {
  ID,
  string,
  stringOr,
  booleanOr,
  boolean: booleanOr(null),
  integer: v =>
    fp.isNil(v) || fp.isNaN(v) || fp.isBoolean(v) ? null : fp.toFinite(v),
};

export const queryTypes = {
  IDSeq: fp.flow(mapSeq(ID), falsyToNull),
  integerSeq: fp.flow(
    mapSeq(fp.flow(v => (fp.isNil(v) || fp.isEmpty(v) ? v : fp.toFinite(v)))),
  ),
  stringSeq: fp.flow(mapSeq(string), falsyToNull),
  date: fp.flow(formatDateToUrl, falsyToNull),
  dateTimeZone: fp.flow(formatDateTimeFullUrl, falsyToNull),
  dateTime: fp.flow(formatDateTimeToUrl, falsyToNull),
  floatSeq: fp.flow(
    mapSeq(fp.flow(v => (fp.isNil(v) || fp.isEmpty(v) ? v : fp.toFinite(v)))),
    falsyToNull,
  ),
};

export const mapSchema = fp.curry((schema, query) => {
  const iterateSchema = (subSchema, subQuery) => {
    const values = toPlainObject(subQuery);

    return _.mapValues(subSchema, (typeOrSchema, key) => {
      const value = _.get(values, key);

      return fp.isFunction(typeOrSchema)
        ? typeOrSchema(value)
        : iterateSchema(typeOrSchema, value);
    });
  };

  return iterateSchema(schema, query);
});
