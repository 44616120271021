import WingApi from "../../helpers/WingApi";
import { getInitialState } from "../../helpers/InitialState";
import {
  getAppMarketplace,
  getAppName,
  getAppToken,
} from "../../../shared/reducers/AppReducer";

const state = getInitialState();
const token = getAppToken(state);
const marketplace = getAppMarketplace(state);
const app = getAppName(state);

export const api: WingApi = new WingApi(token, marketplace, app);
