import { Map, Record, Iterable } from "immutable";
import fp from "lodash/fp";
import { getSize, toArray, toPlainObject } from "./DataUtils";
import { combineCleanQuery } from "../../shared/helpers/UrlUtils";
import { mapSchema } from "../../shared/helpers/ObjectMapper";

const ORDER_DIRECTION_ASC = "asc";
const ORDER_DIRECTION_DESC = "desc";

export const MD_PAGE_SIZE = 50;
export const DEFAULT_PAGE_SIZE = MD_PAGE_SIZE;
export const DEFAULT_MAX_PAGE_SIZE = 200;

const join = fp.flow(toArray, fp.join(","));

const toPositiveInteger = fp.flow(fp.toInteger, fp.clamp(0, Infinity));

const stringifyParam = fp.flow(fp.trim, v => v || null);
const stringifyMapParams = fp.flow(
  toPlainObject,
  fp.mapKeys(stringifyParam),
  fp.mapValues(stringifyParam),
);

const parseValue = (value, notSetValue, parser) =>
  fp.isUndefined(value) ? notSetValue : parser(value);

const DataListFilterRecord = Record({ values: Map() }, "DataListFilter");

export const getListFilterSchema = () => ({
  page: toPositiveInteger,
  size: fp.flow(toPositiveInteger, v => v || DEFAULT_PAGE_SIZE),

  search: stringifyParam,
  search_type: stringifyParam,
  order_by: stringifyParam,
  order_by_direction: stringifyParam,
  logistic_type: stringifyParam,
});

export const filterMapper = mapSchema(getListFilterSchema());

export default class DataListFilter extends DataListFilterRecord {
  constructor(values) {
    super({ values: Map(filterMapper({})).merge(stringifyMapParams(values)) });
  }

  getValue(key: string, notSetValue: any): any {
    return this.getIn(["values", stringifyParam(key)], notSetValue);
  }

  getBoolValue(key: string, notSetValue: boolean = false): boolean {
    return parseValue(
      this.getValue(key),
      notSetValue,
      fp.flow(fp.toLower, fp.eq("true")),
    );
  }

  getNumberValue(key: string, notSetValue: number = 0): number {
    return parseValue(this.getValue(key), notSetValue, fp.toFinite);
  }

  getIntegerValue(key: string, notSetValue: number = 0): number {
    return parseValue(this.getValue(key), notSetValue, fp.toInteger);
  }

  setValue(key: string, value: any): DataListFilter {
    return this.setValueMap({ [key]: value });
  }

  setValueMap(values: Object | Iterable | DataListFilter): DataListFilter {
    return this.update("values", v => v.merge(stringifyMapParams(values)));
  }

  getPage(): number {
    return parseValue(this.getValue("page"), 0, toPositiveInteger);
  }

  getSize(): number {
    return parseValue(
      this.getValue("size"),
      DEFAULT_PAGE_SIZE,
      toPositiveInteger,
    );
  }

  getSearch(): string {
    return this.getValue("search");
  }

  getOrderBy(): string {
    return this.getValue("order_by");
  }

  getLogisticTypeBy(): string {
    return this.getValue("logistic_type");
  }

  getOrderByDirection(): string {
    return this.getOrderBy() ? this.getValue("order_by_direction") : null;
  }

  getLogisticType(): string {
    return this.getLogisticTypeBy() ? this.getValue("logistic_type") : null;
  }

  isOrderByAsc(): boolean {
    return this.getOrderByDirection() === ORDER_DIRECTION_ASC;
  }

  setPage(page: number): DataListFilter {
    return this.setValue("page", toPositiveInteger(page));
  }

  setLogisticType(logisticType: string): DataListFilter {
    return this.setValue("logistic_type", stringifyParam(logisticType));
  }

  incrementPage(): DataListFilter {
    return this.setPage(this.getPage() + 1);
  }

  decrementPage(): DataListFilter {
    return this.setPage(this.getPage() - 1);
  }

  setPageSize(
    size: number,
    maxPageSize = DEFAULT_MAX_PAGE_SIZE,
  ): DataListFilter {
    const pageSize = toPositiveInteger(size) || DEFAULT_PAGE_SIZE;
    return this.withMutations((filter: DataListFilter) => {
      filter
        .setPage(null)
        .setValue(
          "size",
          pageSize > maxPageSize && maxPageSize !== Infinity
            ? maxPageSize
            : pageSize,
        );
    });
  }

  setSearch(search: string): DataListFilter {
    return this.withMutations((filter: DataListFilter) =>
      filter.setPage(null).setValue("search", stringifyParam(search)),
    );
  }

  setOrderBy(key: string): DataListFilter {
    return this.withMutations((filter: DataListFilter) => {
      const cleanKey = stringifyParam(key);

      filter.setPage(null);

      if (cleanKey !== this.getOrderBy()) {
        filter
          .setValue("order_by", key)
          .setValue("order_by_direction", ORDER_DIRECTION_ASC);
      }
    });
  }

  setOrderByDirection(direction: string): DataListFilter {
    if (!this.getOrderBy()) {
      return this;
    }

    return this.withMutations((filter: DataListFilter) => {
      filter
        .setPage(null)
        .setValue(
          "order_by_direction",
          direction === ORDER_DIRECTION_ASC ? direction : ORDER_DIRECTION_DESC,
        );
    });
  }

  setOrderByAsc(isAsc: boolean): DataListFilter {
    return this.setOrderByDirection(
      isAsc ? ORDER_DIRECTION_ASC : ORDER_DIRECTION_DESC,
    );
  }

  toggleOrderBy(key: string): DataListFilter {
    const orderBy = this.getOrderBy();
    const nextKey = stringifyParam(key);

    if (orderBy === nextKey) {
      return this.isOrderByAsc()
        ? this.setOrderByAsc(false)
        : this.setOrderBy(null);
    }

    return this.setOrderBy(nextKey);
  }

  getValues(): Object {
    const values = this.get("values").toJS();
    const queryValues = filterMapper(values);

    return { ...values, ...queryValues };
  }

  toJS() {
    return this.getValues();
  }

  getDefinedValues() {
    return combineCleanQuery(this.getValues());
  }

  /** @deprecated */
  addCustomParam(key: string, value: any): DataListFilter {
    return this.setValue(key, value);
  }

  /** @deprecated */
  toRequestParams(prevQuery: Object): Object {
    return combineCleanQuery(prevQuery, this.getValues());
  }

  /** @deprecated */
  applyQuery(query: Object | Iterable): DataListFilter {
    return this.setValueMap(filterMapper(query));
  }

  /** @deprecated */
  static createFilter(query: Object | Iterable): DataListFilter {
    return new DataListFilter().applyQuery(query);
  }

  static create(values: any): DataListFilter {
    return new DataListFilter(values);
  }

  static createFromIds(ids: any[] | Iterable) {
    return new DataListFilter()
      .setPageSize(getSize(ids))
      .setValue("ids", join(ids));
  }
}
