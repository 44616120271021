import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { withMapInstance } from "react-google-map-components";
import { isEqualData } from "../../helpers/DataUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";

const pickProps = fp.pick(["minZoom", "maxZoom"]);

const enhancer = compose(
  withMapInstance(),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .map(({ map, ...props }) => ({
          map,
          optionProps: Immutable.fromJS(pickProps(props)),
        }))
        .filter(props => props.map)
        .distinctUntilKeyChanged("optionProps", isEqualData)
        .do(({ map, optionProps }) => {
          optionProps.forEach((value, name) => {
            if (!fp.isUndefined(value)) {
              map.set(name, value);
            }
          });
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
);

GoogleMapOptions.propTypes = {
  value: PropTypes.number,

  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
};

function GoogleMapOptions() {
  return null;
}

export default enhancer(GoogleMapOptions);
