import { Observable } from "rxjs";
import fp from "lodash/fp";
import { Request, Response } from "superagent";
import { SIGN_OUT_URL } from "../constants/DashboardPathConstants";

const getResponseBody = fp.get("response.body");
const getResponseCode = fp.get("statusCode");

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const HEAD = "HEAD";
const DELETE = "DELETE";

type HTTPMethod = GET | POST | PUT | HEAD | DELETE;

const DEFAULT_GET_TIMEOUT = 360 * 1000;

export default class WingApi {
  constructor(jwt, marketplace, app, uw) {
    this.jwt = jwt;
    this.marketplace = marketplace;
    this.app = app;
    this.uw = uw;
  }

  fetch(
    url: string,
    options: {
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
      method: HTTPMethod,
      progress(): number,
    },
  ): Promise {
    return createRequest({
      ...options,
      url,
      jwt: this.jwt,
      uw: this.uw,
      marketplace: this.marketplace,
    });
  }

  get(
    url: string,
    options: {
      mode: String,
      params: Object,
      headers: Object,
      timeout: number,
      progress(): number,
    },
  ) {
    return this.fetch(url, { ...options, method: GET });
  }

  post(
    url: string,
    options: {
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
      progress(): number,
    },
  ) {
    return this.fetch(url, { ...options, method: POST });
  }

  put(
    url: string,
    options: {
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
      progress(): number,
    },
  ): Promise {
    return this.fetch(url, { ...options, method: PUT });
  }

  delete(
    url: string,
    options: {
      params: Object,
      headers: Object,
      timeout: number,
      progress(): number,
    },
  ): Promise {
    return this.fetch(url, { ...options, method: DELETE });
  }

  fetchStream(
    url: string,
    options: {
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
      method: HTTPMethod,
    },
  ): Observable {
    return createRequestStream({
      ...options,
      url,
      jwt: this.jwt,
      uw: this.uw,
      marketplace: this.marketplace,
      app: this.app,
    });
  }

  getStream(
    url: string,
    options: {
      params: Object,
      headers: Object,
      timeout: number,
    },
  ): Observable {
    return this.fetchStream(url, { ...options, method: GET });
  }

  postStream(
    url: string,
    options: {
      attachments: Object,
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
    },
  ): Observable {
    return this.fetchStream(url, { ...options, method: POST });
  }

  putStream(
    url: string,
    options: {
      file: File,
      body: Object,
      params: Object,
      headers: Object,
      timeout: number,
    },
  ): Observable {
    return this.fetchStream(url, { ...options, method: PUT });
  }

  deleteStream(
    url: string,
    options: {
      params: Object,
      headers: Object,
      timeout: number,
    },
  ): Observable {
    return this.fetchStream(url, { ...options, method: DELETE });
  }
}

function createRequestStream(options) {
  const {
    app,
    jwt,
    url,
    body,
    file,
    params,
    headers,
    marketplace,
    uw,
    attachments,
    method = GET,
    timeout = Infinity,
    ...restOptions
  } = options;

  /* istanbul ignore if  */
  if (process.env.NODE_ENV !== "production" && !fp.isEmpty(restOptions)) {
    console.warn(
      "Unknown options provided to `createRequestStream({ %s })`",
      fp.keys(restOptions).join(", "),
    );
  }

  return new Observable(observer => {
    const request = new Request(method, url).accept("json");

    if (params) {
      request.query(params);
    }

    if (headers) {
      request.set(headers);
    }

    if (body) {
      request.type("json");

      request.send(body);
    }

    if (jwt) {
      request.set("Authorization", `Bearer ${jwt}`);
    }

    if (marketplace > 0) {
      request.set("marketplace_id", marketplace);
    }

    if (uw) {
      request.set("uw", uw);
    }

    if (file) {
      request.attach("uploadFile", file);
    }

    if (attachments) {
      Object.keys(attachments).forEach(key => {
        request.attach(key, attachments[key]);
      });
    }

    if (fp.isFinite(timeout)) {
      request.timeout(timeout);
    } else if (method === GET) {
      request.timeout(DEFAULT_GET_TIMEOUT);
    }

    observer.next({ progress: 0, pending: true, payload: null });

    request.on("progress", event =>
      observer.next({
        payload: null,
        pending: true,
        progress: (event.loaded * 100) / Math.max(event.loaded, event.total),
      }),
    );

    let complete = false;

    request.end((error: Error, response: Response) => {
      if (error) {
        const responseBody = getResponseBody(error);
        const code = getResponseCode(response);

        // eslint-disable-next-line no-undef
        if (code === 401) window.location.href = SIGN_OUT_URL;

        if (error.message.includes("Request has been terminated")) {
          // Create a new Error object and pass it to observer.error
          const terminationError = new Error(
            "Сетевое соединение нестабильно. Проверьте подключение и свяжитесь с админом при необходимости.",
          );
          observer.error(terminationError);
          return; // Ensure the function returns here
        }

        if (responseBody) {
          // eslint-disable-next-line no-param-reassign
          error.statusCode = responseBody.status;
          // eslint-disable-next-line no-param-reassign
          error.reasonMessage = responseBody.message;
          // eslint-disable-next-line no-param-reassign
          error.response = responseBody.data;
          // eslint-disable-next-line no-param-reassign
          error.responseCode = responseBody.code;
        }

        observer.error(error);
      } else {
        observer.next({
          progress: 100,
          pending: false,
          payload: response.body,
        });
      }

      complete = true;
      observer.complete();
    });

    return () => {
      if (!complete) {
        request.abort();
      }
    };
  }).distinctUntilChanged(fp.isEqual);
  // .retryWhen(errorSubject =>
  //   errorSubject.switchMap(
  //     error =>
  //       method === GET && error.errno === "ETIME"
  //         ? Observable.of(error)
  //         : Observable.throw(error),
  //   ),
  // );
}

function createRequest({ progress, ...request }) {
  let requestStream = createRequestStream(request);

  if (fp.isFunction(progress)) {
    requestStream = requestStream.do(x => {
      if (x.pending) {
        progress(x.progress);
      }
    });
  }

  return requestStream.map(fp.get("payload")).toPromise();
}
