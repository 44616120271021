import { Observable } from "rxjs";
import { setObservableConfig } from "recompose";
import "../../shared/config/ConfigureTheme";
import { configureStore } from "./ConfigureClientStore";
// import { configureEventTracker } from "./ConfigureClientEventTracker";
import { setUserContext, captureException } from "../helpers/ErrorTracker";
import { setUserId } from "../helpers/EventTracker";
import { getInitialState } from "../helpers/InitialState";
// import { getAppName } from "../../shared/reducers/AppReducer";
import { getTokenUserId } from "../../shared/reducers/AuthReducer";

const state = getInitialState();
const storePromise = configureStore(state);
const userId = getTokenUserId(state);

if (userId > 0) {
  setUserId(userId);
  setUserContext(userId);
}

setObservableConfig({
  fromESObservable: Observable.from,
  toESObservable(stream) {
    return stream.catch((error) => {
      captureException(error);

      throw error;
    });
  },
});

// configureEventTracker(getAppName(state));

export const getStore = () => storePromise;
