import { fromJS, Map, List } from "immutable";
import fp from "lodash/fp";
import { combineReducers } from "redux-immutable";
import { cleanupStringArray } from "../helpers/FormatUtils";
import {
  createActionReducer,
  createAsyncFlagReducer,
  createAsyncReducer,
} from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";
import { toJS } from "../helpers/DataUtils";

export const FETCH_PROFILE = "PROFILE/FETCH_PROFILE";
export const UPDATE_PROFILE = "PROFILE/UPDATE_PROFILE";
export const SKIP_VERIFICATION = "PROFILE/SKIP_VERIFICATION";

const selector = injectReducer(
  "profile",
  combineReducers({
    item: createAsyncReducer(
      FETCH_PROFILE,
      Map({ user: null, failed: false }),
      {
        pending: state => state.merge({ failed: false }),
        fulfilled: (state, action) =>
          state.merge({
            failed: false,
            user: fromJS(action.payload.data),
          }),
        rejected: state => state.merge({ user: null, failed: true }),
      },
    ),
    updating: createAsyncFlagReducer(UPDATE_PROFILE),
    skipVerification: createActionReducer(
      SKIP_VERIFICATION,
      false,
      fp.stubTrue,
    ),
  }),
);

export function getUser(state) {
  return selector(state).getIn(["item", "user"]);
}

export function getUserLogin(state) {
  return selector(state).getIn(["item", "user", "login"]);
}

export function getUserOutboundKey(state) {
  return selector(state).getIn(["item", "user", "outbound_key"]);
}

export function getUserInboundKey(state) {
  return selector(state).getIn(["item", "user", "inbound_key"]);
}

export function isUserFetchFailed(state) {
  return selector(state).getIn(["item", "failed"]);
}

export function getUserId(state) {
  return selector(state).getIn(["item", "user", "user_id"]);
}

export function getUserAuthorities(state) {
  return fp.toArray(selector(state).getIn(["item", "user", "roles"]));
}
export function getUserPermissions(state) {
  return fp.toArray(selector(state).getIn(["item", "user", "permissions"]));
}

export function getUserMarketplaceId(state) {
  return fp.toFinite(selector(state).getIn(["item", "user", "marketplace_id"]));
}
export function getUserJurisdiction(state) {
  return selector(state).getIn(["item", "user", "warehouse", "jurisdiction"]);
}

export const getUserWarehouseName = state =>
  selector(state).getIn(["item", "user", "warehouse", "name"]);
export const getUserWarehousesParent = state =>
  selector(state).getIn(["item", "user", "warehouse", "parent"]);

export const getUserWarehouseId = state =>
  fp.toFinite(selector(state).getIn(["item", "user", "warehouse", "id"]));

export const getUserWarehouse = state =>
  selector(state).getIn(["item", "user", "warehouse"]);

export const getUserWarehouses = state =>
  selector(state).getIn(["item", "user", "warehouses"]);

export const getUserWarehousesIds = state => {
  const primaryWarehouseId = getUserWarehouseId(state);
  const additionalWarehouses = getUserWarehouses(state);
  const warehouses = [primaryWarehouseId];

  if (additionalWarehouses && additionalWarehouses.size > 0) {
    return fp.concat(
      warehouses,
      fp.flow(fp.map(v => v.id))(additionalWarehouses.toJS()),
    );
  }

  return warehouses;
};
export const getUserMixedWarehouses = state => {
  const primaryWarehouse = toJS(getUserWarehouse(state));
  const additionalWarehouses = getUserWarehouses(state);
  const warehouses = [primaryWarehouse];

  if (additionalWarehouses && additionalWarehouses.size > 0) {
    return fp.concat(
      warehouses,
      fp.flow(fp.map(v => v))(additionalWarehouses.toJS()),
    );
  }

  return warehouses;
};

function getUserFistName(state) {
  return selector(state).getIn(["item", "user", "first_name"]);
}

function getUserLastName(state) {
  return selector(state).getIn(["item", "user", "last_name"]);
}

export function getUserEmail(state) {
  return selector(state).getIn(["item", "user", "email"]);
}

export const getIsUsingScale = state =>
  selector(state).getIn(["item", "user", "is_using_scale"]);

export const getUserPostcode = state =>
  selector(state).getIn(["item", "user", "postcode", "name"]);
export const getAVVALUserId = state =>
  selector(state).getIn(["item", "user", "avval_user", "uid"]);
export function getUserName(state) {
  const userName = cleanupStringArray([
    getUserFistName(state),
    getUserLastName(state),
  ]).join(" ");

  return userName || getUserEmail(state);
}

export function getUserPhone(state) {
  return selector(state).getIn(["item", "user", "phone"]);
}

export function isUserVerified(state) {
  return selector(state).getIn(["item", "user", "valid_phone"]);
}

export function isUserFetching(state) {
  return selector(state).getIn(["item", "fetching"]);
}

export function isUserUpdating(state) {
  return selector(state).get("updating");
}

export function getSupplierName(state) {
  const supplierName = selector(state).getIn(["item", "user", "data", "name"]);

  return supplierName || getSupplierEmail(state);
}

function getSupplierEmail(state) {
  return selector(state).getIn(["item", "user", "data", "email"]);
}

export function getSupplierLanguage(state) {
  return selector(state).getIn([
    "item",
    "user",
    "data",
    "profile_language_key",
  ]);
}

export function getUserLanguage(state) {
  // if (selector(state).getIn(["item", "user", "user_id"]) === 2)
  //   return UZBEK_LANGUAGE;
  return selector(state).getIn(["item", "user", "language_key"]);
}
// export const getUserLanguage = () => RUSSIAN_LANGUAGE;
// export const getUserLanguage = () => UZBEK_LANGUAGE;

export function hasUserPermission(state, permission) {
  const permissions = selector(state).getIn(
    ["item", "user", "permissions"],
    List(),
  );
  if (!permissions) return false;

  return permissions.toArray().includes(permission);
}
