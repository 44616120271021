import { Set } from "immutable";

export const ADMIN = "admin";
export const CUSTOMER = "customer";
export const SUPPLIER = "supplier";
export const TOOLS = "tools";
export const ALLOCATION = "allocation";
export const QALLOCATION = "qallocation";

export default Set.of(
  ADMIN,
  CUSTOMER,
  SUPPLIER,
  TOOLS,
  ALLOCATION,
  QALLOCATION,
);
