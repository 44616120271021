import _ from "lodash";

const IMPORTANT = "!important";

const makeImportant = value =>
  _.endsWith(value, IMPORTANT) || value === '""'
    ? value
    : `${value} ${IMPORTANT}`;

export default function jssImportant(
  { important: importantByDefault = true } = {},
) {
  return {
    onProcessStyle(style, rule, sheet) {
      let important = Boolean(importantByDefault);

      if (_.has(style, "important")) {
        important = Boolean(style.important);

        // eslint-disable-next-line no-param-reassign
        delete style.important;
      } else if (_.has(sheet, "options.important")) {
        important = Boolean(sheet.options.important);
      }

      if (important) {
        _.forEach(style, (value, key) => {
          if (_.isString(value) || _.isNumber(value)) {
            // eslint-disable-next-line no-param-reassign
            style[key] = makeImportant(value);
          }
        });
      }

      return style;
    },
  };
}
