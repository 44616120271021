import { captureException } from "../helpers/ErrorTracker";

export function errorMiddleware() {
  return () => next => action => {
    try {
      return next(action);
    } catch (e) {
      captureException(e, {
        extra: { data: { action } },
      });

      throw e;
    }
  };
}
