import debug from "debug";

const namespaces = ["App:*", "superagent"].join(",");

debug.enable(namespaces);

try {
  global.localStorage.debug = namespaces;
} catch (e) {}

export const log = debug("App:Log");
