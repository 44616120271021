import { OrderedSet } from "immutable";

export const UZBEK_LANGUAGE = "uz";
export const RUSSIAN_LANGUAGE = "ru";
export const ENGLISH_LANGUAGE = "en";
export const TURKISH_LANGUAGE = "tr";
export const FRENCH_LANGUAGE = "fr";
export const SERBIAN_LANGUAGE = "sr";
export const MONGOLIAN_LANGUAGE = "mn";
export const SPANISH_LANGUAGE = "es";
export const ARABIC_LANGUAGE = "ar";
export const HUNGARIAN_LANGUAGE = "hu";

export const Languages = OrderedSet.of(
  UZBEK_LANGUAGE,
  RUSSIAN_LANGUAGE,
  ENGLISH_LANGUAGE,
);

export function formatLanguage(code, getLocalisationMessage) {
  switch (code) {
    case RUSSIAN_LANGUAGE:
      return getLocalisationMessage("russian", "Russian");
    case UZBEK_LANGUAGE:
      return getLocalisationMessage("uzbek", "Uzbek");
    case TURKISH_LANGUAGE:
      return getLocalisationMessage("turkish", "Turkish");
    case FRENCH_LANGUAGE:
      return getLocalisationMessage("french", "French");
    case SERBIAN_LANGUAGE:
      return getLocalisationMessage("serbian", "Serbian");
    case MONGOLIAN_LANGUAGE:
      return getLocalisationMessage("mongolian", "Mongolian");
    case SPANISH_LANGUAGE:
      return getLocalisationMessage("spanish", "Spanish");
    case ARABIC_LANGUAGE:
      return getLocalisationMessage("arabic", "Arabic");
    case HUNGARIAN_LANGUAGE:
      return getLocalisationMessage("hungarian", "Hungarian");
    default:
      return getLocalisationMessage("english", "English");
  }
}

export const LanguagesList = getLocalisationMessage => [
  {
    code: UZBEK_LANGUAGE,
    name: formatLanguage(UZBEK_LANGUAGE, getLocalisationMessage),
  },
  {
    code: ENGLISH_LANGUAGE,
    name: formatLanguage(ENGLISH_LANGUAGE, getLocalisationMessage),
  },
  {
    code: RUSSIAN_LANGUAGE,
    name: formatLanguage(RUSSIAN_LANGUAGE, getLocalisationMessage),
  },
];
