import { fromJS, Map } from "immutable";
import { injectReducer } from "../helpers/ReducerContext";
import { createActionReducer } from "../../client/helpers/ReducerFactory";

export const INIT_APP = "APP/INIT_APP";

const selector = injectReducer(
  "app",
  createActionReducer(
    INIT_APP,
    Map({
      app: null,
      token: null,
      marketplace: null,
      settings: null,
      theme: null,
      logo: null,
      favicon: null,
      map_key: null,
      map_provider: null,
      map_provider_key: null,
      map_place_api_key: null,
      map_geo_coding_api_key: null,
      lang: "ru",
      marketplace_name: null,
      country: null,
    }),
    (state, { payload }) =>
      state.merge(
        fromJS({
          app: payload.app,
          token: payload.token,
          settings: payload.settings,
          marketplace: payload.marketplace,
          theme: payload.theme,
          logo: payload.logo,
          favicon: payload.favicon,
          map_key: payload.map_key,
          map_provider: payload.map_provider,
          map_provider_key: payload.map_provider_key,
          map_place_api_key: payload.map_place_api_key,
          map_geo_coding_api_key: payload.map_geo_coding_api_key,
          lang: payload.lang,
          marketplace_name: payload.marketplace_name,
          country: payload.country,
        }),
      ),
  ),
);

export const getAppName = state => selector(state).get("app");
export const getAppLogo = state => selector(state).get("logo");
export const getAppFavicon = state => selector(state).get("favicon");
export const getAppTheme = state => selector(state).get("theme");
export const getAppToken = state => selector(state).get("token");
export const getMapKey = state => selector(state).get("map_key");
export const getMapProvider = state => selector(state).get("map_provider");
export const getMapProviderKey = state =>
  selector(state).get("map_provider_key");
export const getMapPlaceApiKey = state =>
  selector(state).get("map_place_api_key");
export const getMapGeoCodingApiKey = state =>
  selector(state).get("map_geo_coding_api_key");
export const isMapKeyAvailable = () => false;
// export const isMapKeyAvailable = state => {
//   const mapKey = getMapProviderKey(state);
//   const mapProvider = getMapProvider(state);
//
//   return (
//     !fp.isNil(mapKey) &&
//     !fp.isEmpty(mapKey) &&
//     mapProvider === GOOGLE_MAP_PROVIDER
//   );
// };
export const getAppMarketplace = state => selector(state).get("marketplace");
export const getMarketplaceLanguage = state => selector(state).get("lang");
export const getMarketplaceName = state =>
  selector(state).get("marketplace_name");
export const getCountry = state => selector(state).get("country");

export const shouldSkipVerification = state =>
  selector(state).getIn(["settings", "skipVerification"]);
