import fp from "lodash/fp";
import transitImmutable from "transit-immutable-js";
import { base64Decode, base64Encode } from "./CryptoUtils";
import DataListFilter from "../../client/helpers/DataListFilter";

const createGlobalHandlers = fp.flow(
  fp.rest(
    fp.map(name => {
      try {
        if (fp.isFunction(global[name])) {
          return {
            tag: `$${name}$`,
            class: global[name],
            read: fp.constant(null),
            write: fp.constant(null),
          };
        }
      } catch (e) {}

      return null;
    }),
  ),
  fp.compact,
);

const globalHandlers = createGlobalHandlers(
  "Blob",
  "File",
  "FileList",
  "FileReader",
  "FileReaderSync",
);

const transit = transitImmutable
  .withRecords([DataListFilter])
  .withExtraHandlers(globalHandlers);

export const toJSON = value => transit.toJSON(value);
export const fromJSON = value => transit.fromJSON(value);

export const toBase64 = fp.flow(toJSON, base64Encode);
export const fromBase64 = fp.flow(base64Decode, fromJSON);
