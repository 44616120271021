import { OrderedSet } from "immutable";

export const RETAIL = "retail";
export const ECOMMERCE = "ecommerce";
export const GROCERY = "grocery";
export const FOOD_DELIVERY = "food_delivery";
export const LAUNDRY = "laundry";
export const COURIER_COMPANY = "courier_company";
export const PHARMACY = "pharmacy";
export const OTHER = "other";

export default OrderedSet.of(
  RETAIL,
  ECOMMERCE,
  GROCERY,
  FOOD_DELIVERY,
  LAUNDRY,
  COURIER_COMPANY,
  PHARMACY,
  OTHER,
);
