import fp from "lodash/fp";
import {
  SIGN_OUT,
  persistToken,
  SIGN_IN_FULFILLED,
  SIGN_UP_FULFILLED,
  FACEBOOK_SIGN_IN_FULFILLED,
} from "../../shared/reducers/AuthReducer";

export function authMiddleware() {
  const getToken = fp.get("data.id_token");

  const signOutActions = { [SIGN_OUT]: true };
  const signInActions = {
    [SIGN_IN_FULFILLED]: true,
    [SIGN_UP_FULFILLED]: true,
    [FACEBOOK_SIGN_IN_FULFILLED]: true,
  };

  return store => next => action => {
    if (fp.isPlainObject(action)) {
      const { type } = action;
      if (signInActions[type]) {
        const token = getToken(action.payload);

        if (token) {
          store.dispatch(persistToken(token));
        }
      } else if (signOutActions[type]) {
        store.dispatch(persistToken(null));
      }
    }

    return next(action);
  };
}
